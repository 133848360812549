'use strict';

function MyAccountInfoCardCtrl($scope, $window, RmxsService, JWTService, CustomerService) {
    'ngInject';
    const vm = this;
    vm.pageParams = {
        detailsLoaded: false,
        id: 0,
        fullName: "",
        city: "",
        state: "",
        otherUsers: 0

    };

    const getCustomerDetail = () => {
        const rmidToPass = JWTService.getRmid();
        CustomerService.getCustomer(rmidToPass).then((res) => {
            vm.pageParams.fullName = res.forename + " " + res.surname;
            vm.pageParams.id = res.internalCustomerId;
            vm.pageParams.city = res.city;
            vm.pageParams.state = res.state;
            vm.pageParams.detailsLoaded = true;
        });

    };

    getCustomerDetail();


}

export default {
    name: 'MyAccountInfoCardCtrl',
    fn: MyAccountInfoCardCtrl
};
