'use strict';

function HttpRequestService($rootScope, $http, $q) {
    'ngInject';

    const service = {};

    service.post = (url, data, config) => {
        const deferred = $q.defer();
        $rootScope.$broadcast("StartLoading");
        $http.post(url, data, config).success((data) => {
            $rootScope.$broadcast("FinishLoading");
            deferred.resolve(data);
        }).error((err) => {
            $rootScope.$broadcast("FinishLoading");
            deferred.reject(err);
        });
        return deferred.promise;
    };

    service.put = (url, data, config) => {
        const deferred = $q.defer();
        $rootScope.$broadcast("StartLoading");
        $http.put(url, data, config).success((data) => {
            $rootScope.$broadcast("FinishLoading");
            deferred.resolve(data);
        }).error((err, status) => {
            $rootScope.$broadcast("FinishLoading");
            deferred.reject(status);
        });
        return deferred.promise;
    };

    service.patch = (url, data, config) => {
        const deferred = $q.defer();
        $rootScope.$broadcast("StartLoading");
        $http.patch(url, data, config).success((data) => {
            $rootScope.$broadcast("FinishLoading");
            deferred.resolve(data);
        }).error((err, status) => {
            $rootScope.$broadcast("FinishLoading");
            deferred.reject(status);
        });
        return deferred.promise;
    };

    service.get = (url, config) => {
        const deferred = $q.defer();
        $http.get(url, config).success((data) => {
            deferred.resolve(data);
        }).error((err, status) => {
            deferred.reject(status);
        });
        return deferred.promise;
    };

    service.delete = (url, config) => {
        const deferred = $q.defer();
        $http.delete(url, config).success((data) => {
            deferred.resolve(data);
        }).error((err, status) => {
            deferred.reject(status);
        });
        return deferred.promise;
    };

    return service;

}

export default {
    name: 'HttpRequestService',
    fn: HttpRequestService
};
