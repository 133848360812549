'use strict';

function MenuStateDirective(menu, Logout, $window, $state) {
    "ngInject";
    return {
        scope: {
            section: '='
        },
        templateUrl: 'templates/menu-state.html',
        link: ($scope, $element) => {
            const controller = $element.parent().controller();
            // $scope.focusSection = () => {
            //   controller.autoFocusContent = true;
            // };
            $scope.isShowNav = () => {
                return controller.isShowNav();
            };
            $scope.changeMenuItemToActive = (err) => {    
                menu.setOpenedSection(err);
            };

            $scope.logout = () => {
                Logout.logout('signout');
            };
    
            $scope.uriClick = (uri) => {
                $window.location.href = uri;
            };

            $scope.stateGo = (state, event) => {
                if (angular.isDefined(state) && angular.isDefined(event) && angular.isDefined(event.keyCode)){
                    if (event.keyCode === 32 || event.keyCode === 13 || event.code === "Space" || event.code === "Enter"){
                        $state.go(state);
                    }         
                } else {
                    if (angular.isDefined(state)){
                        $state.go(state);
                    }
                }
            };

            $scope.getSelectedSectionName = () => {    
                return angular.isDefined(menu.selectedSection()) ? menu.selectedSection().name : '';
            };
        }
    };
}

export default {
    name: 'menuState',
    fn: MenuStateDirective
};
