'use strict';
const dbc = require('designbycontract');

module.exports = function(agencyAbbreviation, environment, cb) {
    dbc.requireWithMessage(arguments.length === 3, 'function called with incorrect number of arguments');
    dbc.requireWithMessage(dbc.isString(agencyAbbreviation), 'Agency Abbreviation must be provided');
    dbc.requireWithMessage(dbc.isString(environment), 'Environment must be provided');
    dbc.requireWithMessage(dbc.isFunction(cb), 'Callback must be provided');

    const request = require('request-promise');

    const options = {
        uri: require('./infoUrl.json').url + '/agencies/v2/' + agencyAbbreviation,
        method: 'GET',
        json: true
    };

    request(options).then(function(result) {
        const url = result.agency.dataCentreURL[environment];
        return cb(null, url);
    }).catch(cb);
};