'use strict';

function DialogService($uibModal, $state, $uibModalStack) {
    'ngInject';

    const service = {};

    service.openPassChangeModal = () => {
        return $uibModal.open({
            templateUrl: 'modals/modal-forgot-password.html',
            clickOutsideToClose: true,
            constrollerAs: 'newPass',
            controller: 'NewPasswordCtrl'
        });
    };

    service.openValEmailResponseModal = () => {
        return $uibModal.open({
            templateUrl: 'modals/modal-password-request-sent.html',
            clickOutsideToClose: true,
            constrollerAs: 'newPass',
            controller: 'NewPasswordCtrl'
        });
    };

    service.openValEmailErrorResponseModal = () => {
        return $uibModal.open({
            templateUrl: 'modals/modal-password-request-error.html',
            clickOutsideToClose: true,
            constrollerAs: 'newPass',
            controller: 'NewPasswordCtrl'
        });
    };

    service.openNoNextTripModal = () => {
        return $uibModal.open({
            templateUrl: 'modals/modal-no-next-trip.html',
            clickOutsideToClose: true,
            controller: function ($scope) {
                $scope.close = () => {
                    $uibModalStack.dismissAll();
                };
            }
        });
    };

    service.openNoNextTripModalFromTripPage = () => {
        return $uibModal.open({
            templateUrl: 'modals/modal-no-next-trip.html',
            clickOutsideToClose: true,
            controller: function ($scope) {
                $scope.close = () => {
                    $uibModalStack.dismissAll();
                };
            }
        });
    };

    return service;
}

export default {
    name: 'DialogService',
    fn: DialogService
};
