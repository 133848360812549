'use strict';

function NewPasswordCtrl($scope, $stateParams, $location, JWTService, $state, Auth, AgencyService, PasswordService, _, EmailService, DialogService, $uibModalInstance)
{
    "ngInject";
    const vm = this;

    const closeModal = () => {
        $uibModalInstance.dismiss('End');
    };

    const openRequestSent = () => {
        DialogService.openValEmailResponseModal();
    };

    const openRequestErrorRes = () => {
        DialogService.openValEmailErrorResponseModal();
    };

    $scope.requestEmail = () => {
        if ($scope.emailInput){
            const agencyToPass = $location.search().a;
            const form = {
                email_value: $scope.emailInput,
                agency_value: agencyToPass
            };
            EmailService.requestPasswordEmail(form)
                .then(() => {
                    vm.reqEmailPos = true;
                    closeModal();
                    openRequestSent();
                })
                .catch(() => {
                    closeModal();
                    openRequestErrorRes();
                });
        }
    };

}
export default {
    name: 'NewPasswordCtrl',
    fn: NewPasswordCtrl
};
