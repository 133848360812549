'use strict';

import { isNull } from "util";

function BookTripCtrl(
    $window,
    RmxsService,
    JWTService,
    _,
    moment,
    TripPlanningService,
    BookingService,
    $state,
    AgencyInfoService,
    $location,
    $anchorScroll,
    AgencyService,
    TimeZoneService,
    TripsService,
    $log,
    $scope,
    ClientAppFeaturesService
) {
    'ngInject';

    $scope.breakdown = {
        open: false
    };

    const vm = this;

    const suspendedDates = [];
    let bookingTrips = [];
    const agency_id = JWTService.getAgencyId();
    vm.isItineraryOpen =
        vm.isDepartureOpen =
        vm.isReturnOpen = true;

    const getFormatDate = (date) => {
        return moment.utc(date).format('YYYY-MM-DD');
    };

    const clearError = () => {
        vm.bookTripError = "";
    };


    const dateDisabled = (data) => {
        const date = getFormatDate(moment(data.date).format());
        if (_.includes(suspendedDates, date)) {
            return true;
        }
    };

    const getCalendar = () => {
        vm.popupDate = {
            opened: false
        };

        vm.openDatePicker = () => {
            vm.popupDate.opened = true;
        };
        const preventToday = !vm.sameDayBooking;
        let start_date_offset = vm.minDaysBooking;

        let currentStartDate = moment().add(start_date_offset, 'days').format();
        const currentEndDate = moment().add(vm.dateRange, 'days').format();

        // Apply Check Next Day Rule using operating hours
        if (vm.operatingHours && start_date_offset === 0 || start_date_offset === 1) {
            if (vm.checkNextDayRule(currentStartDate)) {
                start_date_offset = 2;
                currentStartDate = moment().add(start_date_offset, 'days').format();
            }
        }

        RmxsService.getSuspendedDateRange(JWTService.getRmid(), currentStartDate, currentEndDate).then((dateRanges) => {

            if (preventToday && start_date_offset === 0) {
                suspendedDates.push(getFormatDate(currentStartDate));
            }

            dateRanges.map(function (dateRange) {
                let savedDate = getFormatDate(dateRange.startDate);
                while (moment(savedDate).isSameOrBefore(getFormatDate(dateRange.endDate))) {
                    if (moment(savedDate).isBetween(getFormatDate(currentStartDate), getFormatDate(currentEndDate), null, '[]')) {
                        suspendedDates.push(getFormatDate(savedDate));
                    }
                    savedDate = moment(savedDate).add(1, 'days');
                }
            });

            vm.dateOptions = {
                minDate: currentStartDate,
                maxDate: currentEndDate,
                dateDisabled: dateDisabled
            };
        });
    };


    vm.checkNextDayRule = () => {
        const startHour = vm.operatingHours.startHour;
        const startMinute = vm.operatingHours.startMinute;
        const endHour = vm.operatingHours.endHour;
        const endMinute = vm.operatingHours.endMinute;
        const currenctAgencyDate = vm.agencyTimeZoneInfo.date;
        const currentHour = currenctAgencyDate.getHours();
        const currentMinute = currenctAgencyDate.getMinutes();
        let blockNextDay = false;

        // Check for none operation hours for normal case or overnight case 
        if (startHour < endHour) {
            if ((currentHour > endHour) || (currentHour === endHour && currentMinute > endMinute)) {
                blockNextDay = true;
            }
        } else {
            if ((currentHour < startHour && currentHour > endHour) || (currentHour === startHour && currentMinute < startMinute) || (currentHour === endHour && currentMinute > endMinute)) {
                blockNextDay = true;
            }
        }
        return blockNextDay;
    };


    const getTimeTypes = () => {
        if (vm.timingConstraintPickup && vm.timingConstraintDropoff) {
            vm.timeTypes = [{
                "key": "pick",
                "value": "PICK UP TIME"
            }, {
                "key": "drop",
                "value": "DROP OFF TIME"
            }];
        } else if (vm.timingConstraintPickup === "P") {
            vm.timeTypes = [{
                "key": "pick",
                "value": "PICK UP TIME"
            }];
        } else {
            vm.timeTypes = [{
                "key": "drop",
                "value": "DROP OFF TIME"
            }];
        }
        vm.selectedTimeType = vm.timeTypes[0].value;
        vm.bookTripInfo = {
            ['ty']: vm.timeTypes[0].key,
            destinationphone: null,
            trippurposeid: null,
            tripPurposeType: null
        };
        vm.return = {
            selectedTimeType: vm.selectedTimeType,
            bookTripInfo: Object.assign({}, vm.bookTripInfo)
        };
    };

    const getTodayBookTripRule = (bookableHours = [], bookDate) => {

        $log.debug('getTodayBookTripRule(', moment(bookDate).format('llll'), ')');

        if (!moment(bookDate).isValid())
            return null;

        // sunday, monday, tuesday, etc
        // By default, momentjs comes loaded with English locale strings
        const weekDay = moment(bookDate)
            .format('dddd')
            .toLowerCase();

        const tripRule = bookableHours.find(({ day }) => day.toLowerCase() === weekDay);

        if (angular.isUndefined(tripRule))
            return null;

        const { start, end } = tripRule;

        if (start) {
            const [
                hour = '',
                minute = '',
                startHour = Number.parseInt(hour),
                startMinute = Number.parseInt(minute)
            ] = start.split(':');
            if (!(Number.isNaN(startHour) || Number.isNaN(startMinute)))
                Object.assign(tripRule, { startHour, startMinute });
        }

        if (end) {
            const [
                hour = '',
                minute = '',
                endHour = Number.parseInt(hour),
                endMinute = Number.parseInt(minute)
            ] = end.split(':');
            if (!(Number.isNaN(endHour) || Number.isNaN(endMinute)))
                Object.assign(tripRule, { endHour, endMinute });
        }

        return tripRule;
    };


    const getTimePicker = (isPickup = true) => {
        const now = moment();
        const remainder = vm.minuteInterval - (now.minute() % vm.minuteInterval);
        now.add(remainder, 'minutes');
        if (vm.sameDayBooking) {
            if (isPickup) {
                now.add(vm.minPickupHours, 'hours');
            } else {
                now.add(vm.minHoursDropOffBooking, 'hours');
            }
        }
        return now.toDate();

    };

    const refreshFavAddrs = () => {
        RmxsService.getFavAddrs(JWTService.getRmid()).then((addrs) => {
            vm.displayedFavAddrs = [];
            addrs.map(function (addr) {
                vm.displayedFavAddrs.push({
                    'id': addr.addressId,
                    'address': addr.address + ", " + addr.city + ", " + addr.state,
                    'lat': addr.latitude,
                    'long': addr.longitude
                });
            });
        });
    };


    const getMobilityTypeList = (riderMobilityType) => {
        RmxsService.getMobilityTypeList().then((types) => {
            const mobilityTypeList = [];
            const regex = /[^A-Z0-9]/gi;
            const subst = "";
            types.map(function (type) {
                mobilityTypeList.push(type);

                if (_.isEqual(riderMobilityType, type.mobilityRequirementType)) {
                    vm.bookTripInfo.ridermoid = type.mobilityRequirementId;
                }
                if (_.isEqual(vm.defaultAttendantMobilityType.replace(regex, subst), type.mobilityRequirementType.replace(regex, subst))) {
                    vm.attendantMobilityType = type.mobilityRequirementType;
                    vm.bookTripInfo.attendantmobid = type.mobilityRequirementId;
                    vm.bookTripInfo.attendantMobilityType = type.mobilityRequirementType;
                }
                if (_.isEqual(vm.defaultGuestMobilityType.replace(regex, subst), type.mobilityRequirementType.replace(regex, subst))) {
                    vm.guestMobilityType = type.mobilityRequirementType;
                    vm.bookTripInfo.guestmobid = type.mobilityRequirementId;
                    vm.bookTripInfo.guestMobilityType = type.mobilityRequirementType;
                }
            });
            if (vm.attendantMobilityType === null) {
                vm.attendantMobilityType = riderMobilityType;
                vm.bookTripInfo.attendantmobid = vm.bookTripInfo.ridermoid;
                vm.bookTripInfo.attendantMobilityType = riderMobilityType;
            }
            if (vm.guestMobilityType === null) {
                vm.guestMobilityType = riderMobilityType;
                vm.bookTripInfo.guestmobid = vm.bookTripInfo.ridermoid;
                vm.bookTripInfo.guestMobilityType = riderMobilityType;
            }
            vm.mobilityTypes = mobilityTypeList;
        });
    };

    const getTripPurposeList = () => {
        RmxsService.getTripPurposeList().then((types) => {
            const tripPurposeTypeList = [];
            types.map(function (type) {
                tripPurposeTypeList.push(type);
            });
            vm.tripPurposeTypes = tripPurposeTypeList;
        });
    };


    const setValue = (model, val) => {
        const min = 0;
        const attendantMax = vm.maxAttendants;
        const guestMax = vm.maxGuests;
        const totalMax = vm.maxPeopleBooking;
        const parsedVal = parseInt(val);

        if (!isNaN(parsedVal)) {
            const returnVal = Math.max(min, parsedVal);
            if (model === 'attendant') {
                return Math.min(returnVal, attendantMax);
            } else if (model === 'guest') {
                return Math.min(returnVal, guestMax);
            } else {
                return Math.min(returnVal, totalMax - vm.bookTripInfo.attendantCount);
            }
        }
    };

    vm.minusNum = (model) => {
        if (model === 'attendant' && vm.bookTripInfo.attendantCount > 0) {
            vm.bookTripInfo.attendantCount = setValue('attendant', vm.bookTripInfo.attendantCount - 1);
        } else if (model === 'guest' && vm.bookTripInfo.guestCount > 0) {
            vm.bookTripInfo.guestCount = setValue('guest', vm.bookTripInfo.guestCount - 1);
        }
    };

    vm.plusNum = (model) => {
        if (model === 'attendant') {
            vm.bookTripInfo.attendantCount = setValue('attendant', vm.bookTripInfo.attendantCount + 1);
        } else if (model === 'guest') {
            vm.bookTripInfo.guestCount = setValue('guest', vm.bookTripInfo.guestCount + 1);
        }
    };

    vm.processSelection = (direction, item) => {
        switch (direction) {
        case 'from':
            vm.departAddr = item.address;
            vm.bookTripInfo.origid = item.id;
            vm.bookTripInfo.origaddress = item.address;
            vm.bookTripInfo.origlat = item.lat;
            vm.bookTripInfo.origlong = item.long;
            break;
        case 'to':
            vm.destAddr = item.address;
            vm.bookTripInfo.destid = item.id;
            vm.bookTripInfo.destaddress = item.address;
            vm.bookTripInfo.destlat = item.lat;
            vm.bookTripInfo.destlong = item.long;
            break;
        case 'timeType':
            vm.selectedTimeType = item.value;
            vm.bookTripInfo.ty = item.key;
            vm.bookTripInfo.bookTime = getTimePicker(vm.selectedTimeType === "PICK UP TIME");
            break;
        case 'attendantMobilityType':
            vm.attendantMobilityType = item.mobilityRequirementType;
            vm.bookTripInfo.attendantmobid = item.mobilityRequirementId;
            vm.bookTripInfo.attendantMobilityType = item.mobilityRequirementType;
            break;
        case 'guestMobilityType':
            vm.guestMobilityType = item.mobilityRequirementType;
            vm.bookTripInfo.guestmobid = item.mobilityRequirementId;
            vm.bookTripInfo.guestMobilityType = item.mobilityRequirementType;
            break;
        case 'tripPurposeType':
            vm.tripPurposeType = item.tripPurposeType;
            vm.bookTripInfo.trippurposeid = item.tripPurposeId;
            vm.bookTripInfo.tripPurposeType = item.tripPurposeType;
            break;
        case 'returnTimeType':
            vm.return.selectedTimeType = item.value;
            vm.return.bookTripInfo.ty = item.key;
            vm.return.bookTripInfo.bookTime = getTimePicker(vm.return.selectedTimeType === "PICK UP TIME");
            break;
        }
    };

    const getServiceId = () => {
        RmxsService.getServiceId(JWTService.getRmid()).then((id) => {
            vm.bookTripInfo.serviceId = id.serviceId;
        });
    };

    const getMyMobilityType = () => {
        RmxsService.getMobilityType(JWTService.getRmid()).then((type) => {
            vm.bookTripInfo.myMobilityType = vm.myMobilityType = type.mobilityType;
            getMobilityTypeList(type.mobilityType);
        });
    };


    const getAttendantCount = () => {
        vm.bookTripInfo.guestCount = 0;
        RmxsService.getAttendantCount(JWTService.getRmid())
            .then(riderDefault => vm.bookTripInfo.attendantCount = riderDefault.attendantCount || 0);
    };



    const ChangeTabColor = (id) => {
        vm.tabColor1 = {
            "background-color": "default"
        };
        vm.tabColor2 = {
            "background-color": "default"
        };
        vm.tabColor3 = {
            "background-color": "default"
        };
        vm.tabColor4 = {
            "background-color": "default"
        };
        switch (id) {
        case 1:
            vm.tabColor1 = {
                "background-color": "#1e75bc"
            };
            break;
        case 2:
            vm.tabColor2 = {
                "background-color": "#1e75bc"
            };
            break;
        case 3:
            vm.tabColor3 = {
                "background-color": "#1e75bc"
            };
            break;
        case 4:
            vm.tabColor4 = {
                "background-color": "#1e75bc"
            };
            break;
        }
    };

    vm.toViewTrip = () => {
        vm.next(2);
        TripPlanningService.getItinerary(vm.bookTripInfo).then((trips) => {

            trips.trippurposeid = vm.bookTripInfo.trippurposeid ? vm.bookTripInfo.trippurposeid : undefined;
            trips.destinationphone = vm.bookTripInfo.destinationphone ? vm.bookTripInfo.destinationphone : undefined;

            bookingTrips = [].concat(trips);

            const departStep = trips.steps[0];
            const destStep = trips.steps[(trips.steps.length - 1)];

            vm.itineraryInfo = {
                tripDate: moment(departStep.departTime).format('MMMM Do YYYY'),
                departAddr: departStep.pickup.name,
                destAddr: destStep.dropoff.name,
                departTime: moment(departStep.departTime).format('LT'),
                destTime: moment(destStep.arrivalTime).format('LT'),
                attendantCount: trips.attendcount ? trips.attendcount : 0,
                guestCount: trips.guestcount ? trips.guestcount : 0,
                tripPurposeType: vm.bookTripInfo.tripPurposeType ? vm.bookTripInfo.tripPurposeType : null,
                destinationphone: trips.destinationphone ? trips.destinationphone : null
            };

            vm.mobilityDevices = [];

            if (trips.attendcount > 0 && trips.guestcount > 0) {
                if (_.isEqual(trips.custmobid, trips.guestmobid) && _.isEqual(trips.custmobid, vm.bookTripInfo.attendantmobid)) {
                    vm.mobilityDevices.push({
                        'count': (1 + trips.guestcount + trips.attendcount),
                        'type': vm.bookTripInfo.attendantMobilityType
                    });
                } else if (_.isEqual(trips.custmobid, vm.bookTripInfo.attendantmobid)) {
                    vm.mobilityDevices.push({
                        'count': (1 + trips.attendcount),
                        'type': vm.bookTripInfo.attendantMobilityType
                    }, {
                        'count': (trips.guestcount),
                        'type': vm.bookTripInfo.guestMobilityType
                    });
                } else if (_.isEqual(trips.custmobid, trips.guestmobid)) {
                    vm.mobilityDevices.push({
                        'count': (1 + trips.guestcount),
                        'type': vm.bookTripInfo.guestMobilityType
                    }, {
                        'count': (trips.attendcount),
                        'type': vm.bookTripInfo.attendantMobilityType
                    });
                } else if (_.isEqual(vm.bookTripInfo.attendantmobid, trips.guestmobid)) {
                    vm.mobilityDevices.push({
                        'count': (trips.attendcount + trips.guestcount),
                        'type': vm.bookTripInfo.guestMobilityType
                    }, {
                        'count': 1,
                        'type': vm.bookTripInfo.myMobilityType
                    });
                } else {
                    vm.mobilityDevices.push({
                        'count': trips.attendantCount,
                        'type': vm.bookTripInfo.attendantMobilityType
                    }, {
                        'count': trips.guestcount,
                        'type': vm.bookTripInfo.guestMobilityType
                    }, {
                        'count': 1,
                        'type': vm.bookTripInfo.myMobilityType
                    });
                }
            } else if (_.isNil(trips.attendcount) && _.isNil(trips.guestcount)) {
                vm.mobilityDevices.push({
                    'count': 1,
                    'type': vm.bookTripInfo.myMobilityType
                });
            } else {
                if (trips.attendcount > 0) {
                    vm.mobilityDevices.push({
                        'count': trips.attendcount,
                        'type': vm.bookTripInfo.attendantMobilityType
                    }, {
                        'count': 1,
                        'type': vm.bookTripInfo.myMobilityType
                    });
                } else {
                    vm.mobilityDevices.push({
                        'count': trips.guestcount,
                        'type': vm.bookTripInfo.guestMobilityType
                    }, {
                        'count': 1,
                        'type': vm.bookTripInfo.myMobilityType
                    });
                }
            }
            vm.steps = trips.steps;


            const { isRoundTrip = null } = vm;
            if (isRoundTrip) {

                // Make a copy of outbound
                const returnTripInfo = Object.assign({}, vm.bookTripInfo);
                // Merge from return, overwriting differences (i.e., bookTime)
                Object.assign(returnTripInfo, vm.return.bookTripInfo);
                // Reverse origin/destination
                Object.assign(returnTripInfo,
                    {
                        origid: vm.bookTripInfo.destid,
                        origaddress: vm.bookTripInfo.destaddress,
                        origlat: vm.bookTripInfo.destlat,
                        origlong: vm.bookTripInfo.destlong,

                        destid: vm.bookTripInfo.origid,
                        destaddress: vm.bookTripInfo.origaddress,
                        destlat: vm.bookTripInfo.origlat,
                        destlong: vm.bookTripInfo.origlong,
                    });
                vm.return.bookTripInfo = returnTripInfo;

                TripPlanningService.getItinerary(vm.return.bookTripInfo)
                    .then((trips) => {

                        trips.trippurposeid = vm.bookTripInfo.trippurposeid ? vm.bookTripInfo.trippurposeid : undefined;
                        trips.destinationphone = vm.bookTripInfo.destinationphone ? vm.bookTripInfo.destinationphone : undefined;

                        bookingTrips.push(trips);

                        const departStep = trips.steps[0];
                        const destStep = trips.steps[(trips.steps.length - 1)];

                        // copy from outbound itenerary and overwrite whatever is necessary
                        vm.return.itineraryInfo = {
                            tripDate: moment(departStep.departTime).format('MMMM Do YYYY'),
                            departAddr: departStep.pickup.name,
                            destAddr: destStep.dropoff.name,
                            departTime: moment(departStep.departTime).format('LT'),
                            destTime: moment(destStep.arrivalTime).format('LT'),
                            attendantCount: trips.attendcount ? trips.attendcount : 0,
                            guestCount: trips.guestcount ? trips.guestcount : 0,
                            tripPurposeType: vm.bookTripInfo.tripPurposeType ? vm.bookTripInfo.tripPurposeType : null,
                            destinationphone: trips.destinationphone ? trips.destinationphone : null
                        };
                        vm.return.steps = trips.steps;

                    })
                    .catch(err => {
                        $log.error('Error getting return trip info:', err);
                    });

            }
        });

    };

    const checkPhone = () => {
        if (vm.bookTripInfo.destinationphone && !vm.bookTripInfo.destinationphone.match(vm.phoneNumberRegex)) {
            return false;
        } else {
            return true;
        }
    };

    vm.booking = () => {

        const [departureTrip, returnTrip] = bookingTrips;

        if (!_.isNil(departureTrip)) {
            if (_.isNil(departureTrip.attendcount)) {
                delete departureTrip.attendmobid;
            }
            if (_.isNil(departureTrip.guestcount)) {
                delete departureTrip.guestmobid;
            }
        }

        let departureJourneyId;

        return BookingService.bookingPermitted()    // One last check, just in case
            .then( () => {
                return BookingService.bookTrip(departureTrip, JWTService.getUserId());
            })            
            .then(journey => {

                const { isRoundTrip = false } = vm;

                if (!isRoundTrip) {
                    return Promise.resolve({ id: null });
                } else {
                    ({ id: departureJourneyId } = journey);

                    if (_.isNil(returnTrip.attendcount)) {
                        delete returnTrip.attendmobid;
                    }
                    if (_.isNil(returnTrip.guestcount)) {
                        delete returnTrip.guestmobid;
                    }

                    return BookingService.bookTrip(returnTrip, JWTService.getUserId());

                }

            })
            .then(() => {

                // Succesfully booked
                // Notify Google Analytics
                $window.ga('send', 'event',
                    AgencyService.getAgency(),
                    'book_trip',
                    'success');

                vm.displayForm = 4;
                ChangeTabColor(4);
                $location.hash('top');
                $anchorScroll();
            })
            .catch(err => {
                $log.error("Error during booking:", err);

                // Notify Google Analytics
                $window.ga('send', 'event',
                    AgencyService.getAgency(),
                    'book_trip',
                    'fail');

                if (departureJourneyId) {
                    // Cancel already-booked departure trip
                    $log.debug('Canceling departure journey');
                    TripsService.cancelJourney(parseInt(departureJourneyId))
                        .then(() => {
                            $log.debug('departure journey successfully canceled');
                        })
                        .catch(err => {
                            $log.error('departure journey failed to cancel:', err);
                        });
                }
            });

    };

    vm.viewTrips = () => {
        $state.go('Rider.listTrip');
    };

    vm.bookNew = () => {
        $window.location.reload();
    };

    // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    // need to be clean

    vm.displayForm = 1;

    vm.tabColor1 = {
        "background-color": "#1e75bc"
    };


    vm.next = () => {
        vm.itineraryInfo = {};

        const {
            dt: departureDate = null,
            bookTime: departureTime = null,
        } = vm.bookTripInfo;

        BookingService.bookingPermitted()
            .then( () => {
                vm.bookingPermitted = true;
            }).catch( err => {
                if (err === 402) {
                    $log.debug(BookingService.book_min_balance);
                    vm.bookingPermitted = false;
                    vm.bookTripError = BookingService.book_min_balance;
                }
            });


        const departureIsPickup = (vm.selectedTimeType === "PICK UP TIME");

        const departureDateTime = moment(departureDate);
        if (_.isDate(departureTime))
            departureDateTime
                .hours(departureTime.getHours())
                .minutes(departureTime.getMinutes())
                .seconds(0)
                .tz(vm.agencyTimeZoneInfo.timeZone);

        if (!vm.allowTripBooking)
            vm.bookTripError = "You are not allowed to book trips";
        else if (!_.isDate(departureDate))
            vm.bookTripError = "Please select a day of departure.";
        else if (!_.isDate(departureTime))
            vm.bookTripError = 'Please select a departure time.';
        else if (!isWithinBookableHours(vm.bookableHours, departureDateTime)) {

            const periods = getBookingPeriods(vm.bookableHours, departureDateTime);
            $log.debug('periods:', periods);

            // Use .join(' or ') to connect 0-2 sets of operating hours
            // If the requested date is a 2-day period when the agency is closed,
            // then periods array will be empty
            if (periods.length) {
                vm.bookTripError = `Departure trip time must be within booking period between (${periods.join(' or ')} ${vm.agencyTimeZoneInfo.timeZone}). Please choose a different time`;
            } else {
                vm.bookTripError = `No trips can be booked on ${moment(departureDateTime).format('dddd')}. Please choose a different date and time`;
            }

        } else if (!isEarlyEnough(departureDateTime, departureIsPickup, vm.minPickupHours, vm.minHoursDropOffBooking))
            vm.bookTripError = `Pick up time must be no earlier than ${departureIsPickup ? vm.minPickupHours : vm.minHoursDropOffBooking} hours from now`;

        else if (_.isEmpty(vm.departAddr) || _.isEmpty(vm.destAddr))
            vm.bookTripError = "Please select departing address and destination address.";
        else if (_.isEqual(vm.departAddr, vm.destAddr))
            vm.bookTripError = "Please select a different destination address.";
        else if (!checkPhone() && vm.displayForm === 2)
            vm.bookTripError = "Invalid phone number format (555)-555-5555";
        else if (vm.tripPurposeSelection === 2 && vm.bookTripInfo.trippurposeid === null && vm.displayForm === 2)
            vm.bookTripError = "Trip purpose is required";
        else if (vm.destinationPhoneSelection === 2 && vm.bookTripInfo.destinationphone === null && vm.displayForm === 2)
            vm.bookTripError = "Destination phone number is required";
        else if (!vm.isRoundTrip) {
            // Everything is fine at this point
            vm.bookTripError = null;
            vm.displayForm += 1;
            ChangeTabColor(vm.displayForm);
        } else if (!_.isDate(vm.return.bookTripInfo.bookTime))   // Begin round trip validation
            vm.bookTripError = "Please select a return time";
        else {

            const returnDateTime = moment(departureDate)
                .hours(vm.return.bookTripInfo.bookTime.getHours())
                .minutes(vm.return.bookTripInfo.bookTime.getMinutes())
                .seconds(0)
                .tz(vm.agencyTimeZoneInfo.timeZone);

            const returnIsPickup = (vm.return.selectedTimeType === "PICK UP TIME");

            // if requested return time is before departure time, assume it means the following date
            if (returnDateTime.isBefore(departureDateTime))
                returnDateTime.add(1, 'days');

            if (!isWithinBookableHours(vm.bookableHours, returnDateTime)) {

                const periods = getBookingPeriods(vm.bookableHours, returnDateTime);

                // Use .join(' or ') to connect 1-2 sets of operating hours
                // Since this is a return trip, the outbound trip must have been on an 'open' date
                // So, it's highly unlikely that periods is empty
                if (periods.length) {
                    vm.bookTripError = `Return trip time must be within booking period between (${periods.join(' or ')} ${vm.agencyTimeZoneInfo.timeZone}). Please choose a different time`;
                } else {
                    vm.bookTripError = `No trips can be booked on ${moment(returnDateTime).format('dddd')}. Please choose a different date and time`;
                }

            } else if (!isEarlyEnough(returnDateTime, returnIsPickup, vm.minPickupHours, vm.minHoursDropOffBooking))
                vm.bookTripError = `Pick up time must be no earlier than ${returnIsPickup ? vm.minPickupHours : vm.minHoursDropOffBooking} hours from now`;

            else {    // Everything validated successfully
                // Since the return date could have been adjusted to the following day, save it back to .dt and .bookTime
                vm.return.bookTripInfo.dt = vm.return.bookTripInfo.bookTime = returnDateTime.toDate();
                vm.bookTripError = null;
                vm.displayForm += 1;
                ChangeTabColor(vm.displayForm);
            }
        }

    };

    vm.prev = () => {
        clearError();
        vm.itineraryInfo = {};
        vm.bookTripError = null;
        vm.bookTripInfo.destinationphone = null;
        vm.displayForm -= 1;
        ChangeTabColor(vm.displayForm);
    };

    vm.formData = {};

    vm.processForm = () => { };

    vm.bookTripError = null;
    vm.departAddr = null;
    vm.destinationAddr = null;
    vm.bookTripInfo = {
        destinationphone: null,
        trippurposeid: null,
        tripPurposeType: null
    };
    vm.phoneNumberRegex = /\([0-9]{3}\) [0-9]{3}-[0-9]{4}/;
    vm.phoneFormat = "(555) 555-5555";

    if ($window.navigator.language === "en-au") {
        vm.phoneNumberRegex = /\((\d{2})?\)[ ](\d{4})[-](\d{4})$/;
        vm.phoneFormat = "(55) 5555-5555";
    }

    const getBookTripRules = () => {
        const applicableRules = [
            "MIN-BOOK-PICKUP-PERIOD",
            "MAX-BOOK-PICKUP-PERIOD",
            "MAX-NUMBER-PEOPLE",
            "MAX-NUMBER-ATTENDANTS",
            "MAX-NUMBER-GUESTS",
            "GUESTS-ALLOWED",
            "MODIFY-ATTENDANT-MOB-TYPE",
            "MODIFY-GUEST-MOB-TYPE",
            "JOURNEY-BOOKING-TIME-CONSTRAINT",
            "MIN-BOOK-DROPOFF-PERIOD",
            "DEFAULT-ATTENDANT-MOBILITY-TYPE",
            "DEFAULT-GUEST-MOBILITY-TYPE",
            "SAME-DAY-BOOKING",
            "MINUTE-INTERVAL",
            "BOOKABLE-DATE-RANGE",
            "SHOW-ATTENDANT",
            "MIN-DAYS-BOOKING",
            "ALLOW-TRIP-BOOKING",
            "TRIP-PURPOSE-SELECTION",
            "DESTINATION-PHONE-SELECTION",
            "BOOKABLE-HOURS",
            "OPERATING-HOURS"
        ];

        AgencyInfoService
            .bookTripRules()
            .then(([{ rules: bookTripRules }]) => {
                bookTripRules
                    .filter(({ rule: { meta: { identity } } }) => {
                        return _.includes(applicableRules, identity);
                    })
                    .forEach(({ rule: { meta: { identity }, rule: applicableRule } }) => {
                        // Most of the applicable rules can use the default block
                        // A few bad actors must be handled individually
                        switch (identity) {
                        case "MIN-BOOK-PICKUP-PERIOD":
                            ({
                                minDaysPickupBooking: vm.minPickupDays,
                                minHoursPickupBooking: vm.minPickupHours
                            } = applicableRule);
                            break;
                        case "MAX-BOOK-PICKUP-PERIOD":
                            ({
                                maxDaysPickupBooking: vm.maxPickupDays,
                                maxHoursPickupBooking: vm.maxPickupHours
                            } = applicableRule);
                            break;
                        case "JOURNEY-BOOKING-TIME-CONSTRAINT":
                            ({
                                journeyBookingTimingConstraints: [
                                    vm.timingConstraintPickup,
                                    vm.timingConstraintDropoff
                                ]
                            } = applicableRule);
                            break;
                        case "OPERATING-HOURS":
                            vm.operatingHours =
                                    getTodayBookTripRule(
                                        applicableRule.operatingHours,
                                        vm.agencyTimeZoneInfo.date);
                            $log.debug('vm.operatingHours:', vm.operatingHours);
                            break;
                        default:
                            Object.keys(applicableRule)
                                .forEach(key => {
                                    vm[key] = applicableRule[key];
                                });
                            break;
                        }
                    });

                return Promise.resolve();

            }).then(() => {
                clearError();
                getCalendar();
                getTimeTypes();
                vm.return.bookTripInfo.bookTime = vm.bookTripInfo.bookTime = getTimePicker(vm.selectedTimeType === "PICK UP TIME");
                vm.timePickerInfo = {
                    hstep: 1,
                    mstep: vm.minuteInterval,
                    ismeridian: true
                };
                refreshFavAddrs();
                getMyMobilityType();
                getTripPurposeList();
                getAttendantCount();
                getServiceId();
                vm.isRoundTrip = false;

                vm.bookingPermitted = true;

                return BookingService.bookingPermitted() // Should throw error if booking is not permitted
                    .then( () => {
                        vm.bookingPermitted = true;
                    })
                    .catch( err => {
                        if (err === 402) {
                            $log.debug(BookingService.book_min_balance);
                            vm.bookingPermitted = false;
                            vm.bookTripError = BookingService.book_min_balance;
                        }
                    });
            });
    };

    const isEarlyEnough = (requestedDateTime, isPickup = true, pickup = 0, dropoff = 0) => {

        const now = moment().tz(requestedDateTime.tz());

        // lead time
        const lead = isPickup ? pickup : dropoff;

        return (requestedDateTime.diff(now, 'hours') >= lead);
    };

    const getBookableHours = (bookableHours = [], forDate) => {

        $log.debug('getBookableHours(', forDate.format('lll'), ')');

        if (!moment(forDate).isValid())
            return null;

        const { startHour, startMinute, endHour, endMinute } = getTodayBookTripRule(
            bookableHours,
            forDate.toDate())
            || {};

        if ([startHour, startMinute, endHour, endMinute].some(value => !angular.isNumber(value)))
            return null;

        const start = moment(forDate)
            .hours(startHour)
            .minutes(startMinute);

        const end = moment(forDate)
            .hours(endHour)
            .minutes(endMinute);

        if (start.isAfter(end))
            end.add(1, 'days');

        return { start, end };

    };

    const isWithinBookableHours = (bookableHours = [], requestedDateTime) => {

        $log.debug('isWithinBookableHours(', moment(requestedDateTime).format('llll'), ')');

        if (!moment(requestedDateTime).isValid())
            return false;

        // Just in case, need 2 booking periods:
        // The day before, and the day of.

        const dayBeforeDate = moment(requestedDateTime).add(-1, 'days');
        $log.debug('dayBeforeDate:', moment(dayBeforeDate).format('llll'));

        const dayOf = getBookableHours(bookableHours, requestedDateTime);
        $log.debug('dayOf bookable hours:', moment(dayOf.start).format('llll'), 'to', moment(dayOf.end).format('llll'));
        let dayBefore = getBookableHours(bookableHours, dayBeforeDate);
        $log.debug('dayBefore bookable hours:', moment(dayBefore.start).format('llll'), 'to', moment(dayBefore.end).format('llll'));

        // Adjust dayBefore hours to just the period after midnight (if any)
        const startOfRequestedDate = moment(requestedDateTime).startOf('day');
        $log.debug('startOfRequestedDate:', moment(startOfRequestedDate).format('llll'));

        dayBefore.start = moment.max(dayBefore.start, startOfRequestedDate);
        dayBefore.end = moment.max(dayBefore.end, startOfRequestedDate);

        $log.debug('Adjusted dayBefore bookable hours:', moment(dayBefore.start).format('llll'), 'to', moment(dayBefore.end).format('llll'));

        if (dayOf.start.isSame(dayOf.end)
            && dayBefore.start.isSame(dayBefore.end) ) {
            $log.debug('No bookable hours for day-of or day-before');
            return false;
        }

        // true if requested date/time is between bookable start/end hours on requested date 
        // or within the bookable hours for the day before, if they extend past midnight.
        // Necessary for agencies where operating hours span midnight
        // i.e., Friday hours: 8am to 4am technically ends Saturday morning

        return (
            ( // requested date
                // open on requested date?
                dayOf.end.diff(dayOf.start, 'minutes') > 1
                &&
                // between open hours?
                requestedDateTime.isBetween(dayOf.start, dayOf.end, 'minute', '[]')
            )
            ||
            ( // day before requested date (in case hours span midnight)
                // open on day before?
                dayBefore.end.diff(dayBefore.start, 'minutes') > 1
                &&
                // between open hours?
                requestedDateTime.isBetween(dayBefore.start, dayBefore.end, 'minute', '[]')
            )
        );
    };

    const getBookingPeriods = (hours, requestedDateTime) => {

        $log.debug('getBookingPeriods');

        if (!moment(requestedDateTime).isValid())
            return [];

        // For the error message, need day of booking period
        // and either day before or day after booking period
        const dayOf = getBookableHours(hours, requestedDateTime);

        let periods = [];

        if (isNull(dayOf) || moment(dayOf.start).isSame(dayOf.end))
            return periods;

        // Could be closed that day
        if (dayOf.end.diff(dayOf.start, 'minutes') > 1)
            periods = [`${dayOf.start.format('hh:mma dddd')} to ${dayOf.end.format('hh:mma dddd')}`];

        const dayBefore = getBookableHours(vm.bookableHours, moment(requestedDateTime).add(-1, 'days'));
        if (!isNull(dayBefore) 
            && dayBefore.end.diff(dayBefore.start, 'minutes') > 1
            && moment(requestedDateTime).startOf('day').isBefore(dayBefore.end) )
            // Add to beginning of periods
            periods.unshift(`${dayBefore.start.format('hh:mma dddd')} to ${dayBefore.end.format('hh:mma dddd')}`);

        // Could be empty, if requested date is in 2-day period when agency is closed
        return periods;
    };


    AgencyService.getTimeZone(agency_id).then((data) => {
        vm.agencyTimeZoneInfo = TimeZoneService.dateTo(null, data.name);
        getBookTripRules();
    });

    vm.roundTripEnabled = false;    // default
    ClientAppFeaturesService
        .getClientAppFeatures('browser', 'amble', '1.0.0', AgencyService.getAgency())
        .then(({ features }) => {
            vm.roundTripEnabled = features.some(({ name }) => name === 'amble_round_trip');
        });

}

export default {
    name: 'BookTripCtrl',
    fn: BookTripCtrl
};
