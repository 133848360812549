'use strict';

function TimeZoneService($http, ConfigService, HttpRequestService, moment) {
    'ngInject';

    const service = {};

    service.dateTo = (date, inputTimeZone) =>{

        const agencyTimeZone = {};
        let inputDate = new Date();
        const agencyDate = moment(inputDate).tz(inputTimeZone).format();
        let diffMs = (inputDate - new Date(agencyDate));
        //diff to ajust time
        diffMs = diffMs > 0 ? -diffMs : Math.abs(diffMs);
        const diffDays = Math.floor(diffMs / 86400000); // days
        const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
        const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
        if (date){
            inputDate = date;
        }

        agencyTimeZone.diff = {
            day:diffDays,
            hours:diffHrs,
            minutes:diffMins,
            milliseconds:diffMs
        };

        agencyTimeZone.date = new Date(agencyDate);
        agencyTimeZone.timeZone = inputTimeZone;

        return agencyTimeZone;
    };

    return service;
}

export default {
    name: 'TimeZoneService',
    fn: TimeZoneService
};
