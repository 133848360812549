'use strict';

function ViewNextTripCtrl($stateParams, $scope, $window, RmxsService, _, moment, $state, TripsService, $uibModal, AgencyInfoService, mapModulesSvc, $interval, VehicleViewService, NextTripService, $log, TripDetailsUtils, ClientAppFeaturesService, AgencyService) {
    'ngInject';

    const vm = this;
    let trip = {};
    const mobilityTypeList = {};
    const mobilityTypes = {
        attendant: null,
        guest: null,
        cust: null
    };
    const journeyId = $stateParams.journeyid;

    vm.tripInfo = {
        tripDate: null,
        departAddr: null,
        destAddr: null,
        departTime: null,
        destTime: null,
        tripPurposeId: null,
        tripPurpose: null,
        status: null,
        // RID-2592 - START
        statusString: null,
        statusColor: null,
        statusMessage: null,
        statusIcon: null
        // RID-2592 - END
    };

    vm.showVehicle = false;

    const getTripPurposes = () => {
        RmxsService.getTripPurpose().then((trippurposes) => {
            if (vm.tripInfo.tripPurposeId !== null && vm.tripInfo.tripPurposeId !== 0) {
                const tripPurpose = trippurposes.filter((tp) => {
                    return tp.tripPurposeId === vm.tripInfo.tripPurposeId;
                });
                vm.tripInfo.tripPurpose = tripPurpose[0].tripPurposeType;
            }
        });
    };

    const vehicleAppear = (departTime) => {
        const now = moment();
        if (departTime.isSame(now, 'd')) {
            const departTimeMinusRule = departTime.subtract(vm.businessRules.showVehiclePriorToScheduleTime, 'seconds');
            if (now >= departTimeMinusRule) {
                return true;
            }
            else {
                return false;
            }
        }
        return false;
    };

    const toRadians = (degrees) => {
        const pi = Math.PI;
        return degrees * (pi / 180);
    };

    const toDegrees = (radians) => {
        const pi = Math.PI;
        return radians * (180 / pi);
    };

    const getCenter = (lat1, long1, lat2, long2) => {
        const radlat1 = toRadians(lat1),
            radlat2 = toRadians(lat2),
            radlong1 = toRadians(long1),
            dlon = toRadians(long2 - long1),
            bx = Math.cos(radlat2) * Math.cos(dlon),
            by = Math.sin(radlat2) * Math.sin(dlon),
            lat3 = Math.atan2(Math.sin(radlat1) + Math.sin(radlat2), Math.sqrt((Math.cos(radlat1) + bx) * (Math.cos(radlat1) + bx) + by * by)),
            long3 = radlong1 + Math.atan2(by, Math.cos(radlat1) + bx);
        return [toDegrees(long3), toDegrees(lat3)];
    };

    const loadMap = () => {
        const reqModules = ['esri/map', "esri/geometry/Point", "esri/symbols/PictureMarkerSymbol",
            "esri/graphic", "esri/layers/GraphicsLayer", "esri/graphicsUtils"];
        mapModulesSvc.loadEsriSDK().then(() => {
            mapModulesSvc.loadModules(reqModules).then((modules) => {
                mapModulesSvc.setMap(modules[0]);
                mapModulesSvc.setPoint(modules[1]);
                mapModulesSvc.setPictureMarkerSymbol(modules[2]);
                mapModulesSvc.setGraphic(modules[3]);
                mapModulesSvc.setGraphicLayer(modules[4]);
                const center = getCenter(vm.departlat, vm.departlong, vm.destlat, vm.destlong),
                    mapDiv = $window.document.querySelector('.map');
                vm.map = mapModulesSvc.getMap(mapDiv, 'gray-vector', center);

                const Point = mapModulesSvc.getPoint(),
                    PictureMarkerSymbol = mapModulesSvc.getPictureMarkerSymbol(),
                    Graphic = mapModulesSvc.getGraphic(),
                    departPoint = new Point(vm.departlong, vm.departlat),
                    departSymbol = new PictureMarkerSymbol('images/ic-pickup-loc-2.png', 120, 130),
                    destPoint = new Point(vm.destlong, vm.destlat),
                    destSymbol = new PictureMarkerSymbol('images/ic-dropoff-loc-1.png', 120, 130),
                    departGraphic = new Graphic(departPoint, departSymbol),
                    destGraphic = new Graphic(destPoint, destSymbol),
                    layer = mapModulesSvc.getGraphicLayer();
                layer.add(departGraphic);
                layer.add(destGraphic);
                const graphicsUtils = modules[5];
                vm.map.setExtent(graphicsUtils.graphicsExtent(layer.graphics).expand(2));

                if (vehicleAppear(vm.departObject)) {
                    let vehicleAngle,
                        vehiclePoint,
                        vehicleSymbol,
                        vehicleGraphic;
                    VehicleViewService.getVehicleLocation(vm.tripInfo.vehicleId, moment().toISOString().substr(0, 10))
                        .then((resp) => {
                            vm.showVehicle = true;
                            vehicleAngle = resp[0].Heading - 256;
                            vehiclePoint = new Point(resp[0].Longitude, resp[0].Latitude);
                            vehicleSymbol = new PictureMarkerSymbol('images/ic-small-bus@3x.png', 58.8, 39.7);
                            vehicleSymbol.setAngle(vehicleAngle);
                            vehicleGraphic = new Graphic(vehiclePoint, vehicleSymbol);
                            layer.add(vehicleGraphic);
                            vm.map.setExtent(graphicsUtils.graphicsExtent(layer.graphics).expand(2));
                        }).catch(() => $log.error("Vehicle Data Unavailable"));

                    let stopVehicleUpdates = $interval(() => {
                        VehicleViewService.getVehicleLocation(vm.tripInfo.vehicleId, moment().toISOString().substr(0, 10))
                            .then((resp) => {
                                layer.remove(vehicleGraphic);
                                vehicleAngle = resp[0].Heading - 256;
                                //$log.log(resp[0].Longitude, resp[0].Latitude);
                                vehiclePoint = new Point(resp[0].Longitude, resp[0].Latitude);
                                vehicleSymbol = new PictureMarkerSymbol('images/ic-small-bus@3x.png', 58.8, 39.7);
                                vehicleSymbol.setAngle(vehicleAngle);
                                vehicleGraphic = new Graphic(vehiclePoint, vehicleSymbol);
                                layer.add(vehicleGraphic);
                            }).catch(() => $log.error("Vehicle Data Unavailable"));
                    }, 30000);

                    $scope.$on('$destroy', () => {
                        if (angular.isDefined(stopVehicleUpdates)) {
                            $interval.cancel(stopVehicleUpdates);
                            stopVehicleUpdates = undefined;
                        }
                    });
                }

                if (vm.dataAvailable()) {
                    vm.map.addLayer(layer);

                }
            });
        });
    };


    const getMobilityTypes = () => {
        vm.mobilityDevices = [];

        if (trip.attendcount > 0 && trip.guestcount > 0) {

            if (_.isEqual(trip.custmobid, trip.guestmobid) && _.isEqual(trip.custmobid, trip.attendmobid)) {
                vm.mobilityDevices.push({ 'count': (1 + trip.guestcount + trip.attendcount), 'type': mobilityTypes.attendant });
            }
            else if (_.isEqual(trip.custmobid, trip.attendmobid)) {
                vm.mobilityDevices.push({ 'count': (1 + trip.attendcount), 'type': mobilityTypes.attendant }, { 'count': (trip.guestcount), 'type': mobilityTypes.guest });
            }
            else if (_.isEqual(trip.custmobid, trip.guestmobid)) {
                vm.mobilityDevices.push({ 'count': (1 + trip.guestcount), 'type': mobilityTypes.guest }, { 'count': (trip.attendcount), 'type': mobilityTypes.attendant });
            }
            else if (_.isEqual(trip.attendmobid, trip.guestmobid)) {
                vm.mobilityDevices.push({ 'count': (trip.attendcount + trip.guestcount), 'type': mobilityTypes.guest }, { 'count': 1, 'type': mobilityTypes.cust });
            }
            else {
                vm.mobilityDevices.push({ 'count': trip.attendantCount, 'type': mobilityTypes.attendant }, { 'count': trip.guestcount, 'type': mobilityTypes.guest }, { 'count': 1, 'type': mobilityTypes.cust });
            }
        }
        else if (_.isNil(trip.attendcount) && _.isNil(trip.guestcount)) {
            vm.mobilityDevices.push({ 'count': 1, 'type': mobilityTypes.cust });
        }
        else {
            if (trip.attendcount > 0) {
                vm.mobilityDevices.push({ 'count': trip.attendcount, 'type': mobilityTypes.attendant }, { 'count': 1, 'type': mobilityTypes.cust });
            }
            else if (trip.guestcount > 0) {
                vm.mobilityDevices.push({ 'count': trip.guestcount, 'type': mobilityTypes.guest }, { 'count': 1, 'type': mobilityTypes.cust });
            }
        }
        getTripPurposes();
    };

    const getMobilityTypeList = () => {
        RmxsService.getMobilityTypeList().then((types) => {
            types.map(function (type) {
                mobilityTypeList[type.mobilityRequirementId] = type.mobilityRequirementType;
            });
            if (!_.isNil(trip.attendcount)) {
                vm.tripInfo.attendantCount = trip.attendcount;
                mobilityTypes.attendant = mobilityTypeList[trip.attendmobid];
            } else {
                vm.tripInfo.attendantCount = 0;
            }
            if (!_.isNil(trip.guestcount)) {
                vm.tripInfo.guestCount = trip.guestcount;
                mobilityTypes.guest = mobilityTypeList[trip.guestmobid];
            } else {
                vm.tripInfo.guestCount = 0;
            }
            if (!_.isNil(trip.custid)) {
                mobilityTypes.cust = mobilityTypeList[trip.custmobid];
            }
            getMobilityTypes();
        });
    };

    // RID-2592: As a rider, I should see my negotiated requested pickup/initial requested pickup, or scheduled time window displayed in the trip details of my trip, so I will be ready on time (Rider Portal)
    const getTripDetail = () => {
        vm.tripInfo.tripDate = moment(trip.steps[0].tripDate).format('MMMM Do YYYY');
        vm.tripInfo.departAddr = trip.steps[0].pickup.name;
        vm.tripInfo.destAddr = trip.steps[trip.steps.length - 1].dropoff.name;
        vm.departObject = moment(trip.steps[0].departTime);

        // RID-85: Add new properties and dynamically update them - START
        vm.tripInfo.computedBaseTime = trip.steps[0].scheduleTimeBasis ? trip.steps[0].scheduleTimeBasis : null;
        vm.tripInfo.computedDepartTimeLate = trip.steps[0].departTimeLate ? trip.steps[0].departTimeLate : null;
        vm.tripInfo.computedDepartTimeEarly = trip.steps[0].departTimeEarly ? trip.steps[0].departTimeEarly : null;
        vm.tripInfo.isPassengerOnboard = trip.steps[0].isPassengerOnboard;
        vm.tripInfo.status = (vm.tripInfo.isPassengerOnboard) ? 6 : trip.steps[0].status;
        vm.tripInfo.isADA = trip.steps[0].isADA;
        vm.tripInfo.isPickup = (trip.steps[0].timingPreference === "P");
        vm.tripInfo.destTime = moment(trip.steps[trip.steps.length - 1].arrivalTime).format('LT'); //destTime = arrivalTime            
        vm.tripInfo.departTime = moment(trip.steps[0].departTime).format('LT');
        vm.tripInfo.tripPurposeId = trip.trippurposeid ? trip.trippurposeid : null;
        vm.tripInfo.destinationphone = trip.destinationphone ? trip.destinationphone : null;

        // RID-2681: User is able to cancel a trip with CANCEL-TRIP-DISABLE business rule set to true (Portal)
        // AND
        // RID-2982: If the driver is 1 minute late, the trip is displaying as a cancelled trip - need to handle with business rule
        // AND
        // RID-3190: small bug where if the status of the trip is 4 (cancelled) do not show cancel button
        const isTripWithinScheduledThreshold = TripDetailsUtils.isTripWithinScheduledThreshold(trip.steps[0].departTime,
            vm.businessRules.schedTripsThresholdMinutes);
        vm.showCancelTripButton = !vm.businessRules.cancelTripDisable && isTripWithinScheduledThreshold && vm.tripInfo.status !== 4;

        if (trip.steps[0].pickup.hasOwnProperty('Location')) {
            const index = trip.steps[0].pickup.Location;
            vm.departlat = index.Lat ? index.Lat : null;
            vm.departlong = index.Long ? index.Long : null;
        }
        else {
            vm.departlat = null;
            vm.departlong = null;
        }
        if (trip.steps[trip.steps.length - 1].dropoff.hasOwnProperty('Location')) {
            const index = trip.steps[trip.steps.length - 1].dropoff.Location;
            vm.destlat = index.Lat ? index.Lat : null;
            vm.destlong = index.Long ? index.Long : null;
        }
        else {
            vm.destlat = null;
            vm.destlong = null;
        }

        getMobilityTypeList(trip, mobilityTypeList, mobilityTypes);

        vm.tripInfo.steps = trip.steps;
        vm.tripInfo.steps.filter((step) => {
            if (step.travelMode === 'PARA') {
                vm.tripInfo.vehicleId = step.vehicleId;
            }
        });

        // One merge of new/dynamic values into this 'vm' objects
        const updatedPropertiesToMerge = TripDetailsUtils.calculateTimeToDisplay(trip, vm);
        Object.assign(vm.tripInfo, updatedPropertiesToMerge);
        // RID-85: Add new properties and dynamically update them - END

        // RID-3042: Amble should not display the trip itinerary if the agency is not multi-modal
        ClientAppFeaturesService
            .getClientAppFeatures('browser', 'amble', '1.0.0', AgencyService.getAgency())
            .then(({ features: clientAppFeatures }) => {
                $log.debug(`Features found in DB: ${angular.toJson(clientAppFeatures)}`);
                vm.showTripItinerary = false; //default
                vm.showTripItinerary = clientAppFeatures.some(({ name }) => name === 'amble_show_itinerary');
            }).then(() => {
                // DEX-83: Issue with ETA display and Pickup window display for MBTA
                const { status, steps } = vm.tripInfo;
                const showVehiclePriorToScheduleTime = vm.businessRules.showVehiclePriorToScheduleTime;
                vm.tripInfo.departTimeMap = TripDetailsUtils.setETAMapTime({ status, steps, showVehiclePriorToScheduleTime });
                vm.showTimeMapBox = (vm.tripInfo.departTimeMap !== null);
            }).catch(function (err) {
                $log.error(err);
                vm.errorMessage = err;
            });
    };

    vm.dataAvailable = () => {
        if (vm.departlat === null || vm.departlong === null || vm.destlat === null || vm.destlong === null) {
            return false;
        }
        else {
            return true;
        }
    };

    const journeyLoad = (tripId) => {
        const journeyId = tripId;
        const billInfo = true;
        TripsService.getSpecJourney(journeyId, billInfo)
            .then(function (journBreakdown) {
                trip = journBreakdown.journey;
                return null;
            })
            .then(() => {
                getTripDetail();
                return null;
            }).then(() => {
                loadMap();
                return null;
            })
            .catch(function (err) {
                vm.errorMessage = err;
            });
    };

    const openResultModal = (message, imgAdrr) => {
        vm.resultMessage = message;
        $scope.resultImage = imgAdrr;
        vm.modalInstanceResult = $uibModal.open({
            templateUrl: 'modals/modal-cancel-journey-outcome.html',
            scope: $scope
        });
    };

    const cancelTrip = () => {
        vm.resultMessage = "";
        TripsService.cancelJourney(parseInt(journeyId))
            .then(() => {
                openResultModal("The journey has been canceled", "../../images/nav_icons/success-mark@3x.png");
                vm.tripInfo.status = 4;
            })
            .catch((err) => {
                vm.errorMessage = err;
                openResultModal("There has been an error", "../../images/header/failure_icon.png");
            });
    };

    const closeModal = () => {
        vm.modalInstance.dismiss();
    };

    vm.exitConfirmModal = () => {
        vm.modalInstanceResult.dismiss();
        $window.location.reload();
    };

    vm.cancelTripModalConfirm = () => {
        closeModal();
        cancelTrip();
    };

    const openCancelJourneyModal = () => {
        vm.modalInstance = $uibModal.open({
            templateUrl: 'modals/modal-cancel-journey.html',
            scope: $scope
        });
    };

    const isCancellable = (tripDate, departTime) => {
        let cancellable = true;
        const tripStart = moment(tripDate + " " + departTime, ["MMMM Do YYYY hh:mma"]);
        const now = moment().add(vm.minutesRestrictCancel, 'm');
        if (now >= tripStart) {
            cancellable = false;
        }
        else {
            cancellable = true;
        }
        return cancellable;
    };

    vm.cancelTrip = () => {
        if (isCancellable(vm.tripInfo.tripDate, vm.tripInfo.departTime)) {
            openCancelJourneyModal();
        }
        else {
            openResultModal("You CANNOT cancel a trip that already begun or this close to it's departure", "../../images/header/failure_icon.png");
            vm.tripInfo.status = 4;
        }
    };

    vm.closeModalWindow = () => {
        closeModal();
    };

    const getBusinessRules = () => {
        // Need to get all applicable business rules and add them to vm object
        vm.businessRules = {};

        // we can call business rule service once for multi category rules, add category needed in below array
        const businessRuleCategories = ['booking', 'customisation'];
        AgencyInfoService.multiCategoryBusinessRules(businessRuleCategories)
            .then((allRules) => {
                const applicableRules = allRules
                    .map(({ rules }) => (rules))
                    .flat()
                    .filter(({ rule: { meta: { identity } } }) => TripDetailsUtils.applicableRules.includes(identity))
                    .map(({ rule: { rule: applicableRule } }) => applicableRule);

                // properly flattened, merged, and spread rules starting with array of ALL rules from all applicable categories
                Object.assign(vm.businessRules, ...applicableRules); // merge spread applicables rules into this vm object

                return NextTripService.getNextTrip()
                    .then(trip => {
                        if (trip) {
                            journeyLoad(trip.id);
                        } else {
                            $state.go("Rider.home");
                        }
                    });
            });
    };
    getBusinessRules();
}

export default {
    name: 'ViewNextTripCtrl',
    fn: ViewNextTripCtrl
};
