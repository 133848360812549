'use strict';

function LoginCtrl($location, _, $scope, $state, AgencyService,
    $log, JWTService, Auth, LoginService, $window,
    ConfigService, AgencyInfoService, DialogService, $uibModal, EmailService, $rootScope, configBaseUrl) {
    "ngInject";

    const vm = this;
    const AssetUrl = ConfigService.getAssetUrl();

    const agency = $location.search().a;
    vm.configBaseUrl = configBaseUrl;
    AgencyService.loadCustomCSS(AgencyInfoService);

    vm.signUp = () => {
        if (agency === 'au_drt') {
            return false;
        }
        else {
            return true;
        }
    };

    vm.images = [];
    vm.images.right_main_logo = `${AssetUrl}${AgencyInfoService.login_reg.right_main_logo}`;
    vm.images.right_app_logo = `${AssetUrl}${AgencyInfoService.login_reg.right_app_logo}`;

    if (!_.isUndefined(agency)) {
        AgencyService.getUrls(agency).then((data) => {
            if (!(data.length < 2)) {
                $scope.isNotBranded = false;
                $scope.isBranded = true;
                if (data[0].asset_category === 'background') {
                    $scope.brandedBG = String(data[0].url);
                    $scope.brandedLogo = String(data[1].url);
                } else {
                    $scope.brandedBG = String(data[1].url);
                    $scope.brandedLogo = String(data[0].url);
                }
            }
        });
    }

    vm.forgotPassword = () => {
        DialogService.openPassChangeModal();
    };

    vm.loginError = null;

    vm.login = () => {
        let params;
        const agency = $location.search().a;
        vm.user.agency = agency;

        if ($location.search().u) {
            vm.hideEmail = true;
            vm.user.rmid = $location.search().u;
        }

        LoginService.login(vm.user, params).then((data) => {
            JWTService.setToken(data.id_token);
            if (Auth.isFacility()) {
                JWTService.removeToken();
                $window.location.href = ConfigService.api.FACILITY_LOGIN + "?t=" + data.id_token;
            } else if (Auth.isRider()) {
                const validated = JWTService.isValidated();
                if (validated) {
                    AgencyService.setAgency(vm.user.agency);
                    Auth.setUserRole(JWTService.getUserRole());
                    $state.go('Rider.home');
                }
                else {
                    vm.modalInstance = $uibModal.open({
                        templateUrl: 'modals/modal-email-validated-error.html',
                        controller: function ($scope) {
                            $scope.resendVerification = () => {
                                EmailService.requestConfirmationEmail();
                                const modalScope = $rootScope.$new();
                                vm.modalInstance.close('Sent');
                                modalScope.modalInstance = $uibModal.open({
                                    templateUrl: 'modals/modal-resend-verification-success.html',
                                    controller: function ($scope) {
                                        $scope.close = () => {
                                            return modalScope.modalInstance.close('Sent');
                                        };
                                    }
                                });
                                return modalScope.modalInstance;
                            };
                            $scope.close = () => {
                                return vm.modalInstance.close('Confirmed');
                            };
                        }
                    });
                    return vm.modalInstance.result;
                }
            } else if (Auth.isAdministrator()) {
                JWTService.removeToken();
                $log.error('Login Error', { 'success': 'false', 'mesage': 'Password Error' });
            }

            // Set/reset Google Analytics ID
            if (localStorage.getItem('agencyAbrv') === 'au_drt') {
                $window.ga('create', $window.gaid, 'auto');
                $window.ga('send', 'pageview');
            } else {
                $window.ga('remove');
            }

            // should handle any other possible result
        }, (err) => {
            if (err && err.message == "Password Error") {
                vm.loginError = "Email and/or Password is incorrect";
                $log.error('Login Error', err);
            } else {
                vm.loginError = err ? err.message : 'Unknown';
                $log.error('Login Error', vm.loginError);
            }
        });
    };

    vm.reg = () => {
        const agency = $location.search().a;
        $state.go('RegLogin.register', { a: agency });
    };

}

export default {
    name: "LoginCtrl",
    fn: LoginCtrl
};
