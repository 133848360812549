'use strict';

function AgencyInfoService(APP_CONFIG, AgencyService, $location, HttpRequestService, ConfigService, $locale) {
    'ngInject';

    const service = {};
    const AgencyInfo = APP_CONFIG.AgencyInfo;
    const agencyAbrv = $location.search().a || AgencyService.getAgency();
    const agency = angular.isUndefined(AgencyInfo[agencyAbrv]) ? "default" : agencyAbrv;
    service.css = AgencyInfo[agency].css || "";

    const assetImages = `assets/images/${agencyAbrv}`;

    service.login_reg = {
        left_main_logo: `${assetImages}/left-main-logo.svg`,
        left_main_logo_text: AgencyInfo[agency].login_reg.left_main_logo_text || '',
        right_main_logo: `${assetImages}/right-main-logo.svg`,
        right_app_logo: `${assetImages}/app-icon.svg`,
    };

    service.header = {
        main_logo: `${assetImages}/header-logo.svg`,
    };

    service.dashboard = {
        main_logo: `${assetImages}/dash-logo.svg`,
        main_text: AgencyInfo[agency].dashboard.main_text || ''
    };

    service.book_trip = {
        guest_max: AgencyInfo[agency].book_trip.guest_max,
        start_date_offset: AgencyInfo[agency].book_trip.start_date_offset,
        date_range: AgencyInfo[agency].book_trip.date_range,
        prevent_today: AgencyInfo[agency].book_trip.prevent_today,
        guest_mobility: AgencyInfo[agency].book_trip.guest_mobility,
        attAndGuestEditable: AgencyInfo[agency].book_trip.attAndGuestEditable,
        max_attendant: AgencyInfo[agency].book_trip.max_attendant,
        timeTypes: AgencyInfo[agency].book_trip.timeTypes
    };

    service.bookTripRules = () => {
        const querystring = "?categories=booking&agency=" + agencyAbrv + "&limit=99&order=rule";
        return HttpRequestService.get(ConfigService.api.GET_RULES + querystring, {
            skipAuthorization: true
        });
    };

    service.customisationRules = () => {
        const querystring = "?categories=customisation&agency=" + agencyAbrv + "&limit=99&order=rule";
        return HttpRequestService.get(ConfigService.api.GET_RULES + querystring, {
            skipAuthorization: true
        });
    };

    // Used for multi categories in one call where "categories" parameter is an array of strings of categories
    service.multiCategoryBusinessRules = (categories) => {
        let queryString = `${ConfigService.api.GET_RULES}?agency=${agencyAbrv}`;
        categories.forEach(category => {
            queryString += `&categories=${category}`;
        });
        return HttpRequestService.get(queryString, { skipAuthorization: true });
    };

    service.getResources = resourceType => {
        // /agencyresources/api/v2/resources/:agency/:resourceType
        const resourceURL = ConfigService.api.GET_RESOURCES + '/' + agencyAbrv + '/' + resourceType + '/?clientType=browser&language=' + $locale.id + "&asOfDate=" + (new Date()).toISOString().substr(0, 10);
        return HttpRequestService.get(resourceURL, {
            skipAuthorization: true
        });
    };

    service.policy = {
        isEmpty: AgencyInfo[agency].policy.isEmpty
    };

    service.contact = {
        address: AgencyInfo[agency].contact.address,
        email: AgencyInfo[agency].contact.email,
        title: AgencyInfo[agency].contact.title,
        sec1: AgencyInfo[agency].contact.sec1,
        sec2: AgencyInfo[agency].contact.sec2,
        sec1phone: AgencyInfo[agency].contact.sec1phone,
        sec2phone: AgencyInfo[agency].contact.sec2phone,
        customsec: AgencyInfo[agency].contact.customsec,
        website: AgencyInfo[agency].contact.website,
        customTemplate: AgencyInfo[agency].contact.customTemplate
    };

    service.showFullContact = {
        showFullContact: AgencyInfo[agency].showFullContact
    };

    service.showPolicy = {
        display: AgencyInfo[agency].policyshow
    };

    return service;
}

export default {
    name: 'AgencyInfoService',
    fn: AgencyInfoService
};
