'use strict';

function EmailService(HttpRequestService, ConfigService) {
    'ngInject';

    const service = {};



    service.requestPasswordEmail = (userValues) => {
        const form = {
            email: userValues.email_value,
            agency: userValues.agency_value
        };
        return HttpRequestService.post(ConfigService.api.REQUEST_PASSWORD_EMAIL, form, {
            skipAuthorization: true
        });
    };

    service.requestConfirmationEmail = () => {
        return HttpRequestService.patch(ConfigService.api.REQUEST_CONFIRMATION_EMAIL, null, {
        });
    };


    return service;
}

export default {
    name: 'EmailService',
    fn: EmailService
};
