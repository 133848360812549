'use strict';

function TripsService($http, ConfigService, HttpRequestService) {
    'ngInject';

    const service = {};

    service.getTrips = (userid, rmid, start, end) => {
        const url = ConfigService.api.GET_TRIPS + userid + "?rmid=" + rmid + "&startDate=" + start + "&endDate=" + end;
        return HttpRequestService.get(url);
    };


    service.getJourneys = (userId, startDate, endDate, rmid, billingInformation) => {

        const url = ConfigService.api.GET_JOURNEYS + "/" + userId;
        const form = {
            params: {
                "startDate": startDate,
                "endDate": endDate,
                "rmid": rmid,
                "billingInformation": billingInformation
            }
        };
        return HttpRequestService.get(url, form);
    };

    service.getSpecJourney = (journId, billingInformation) => {

        const url = ConfigService.api.GET_SPECIFIC_JOURNEY + "/" + journId;
        const form = {
            params: {
                "billingInformation": billingInformation
            }
        };
        return HttpRequestService.get(url, form);
    };

    service.cancelJourney = (journeyId) => {
        const url = ConfigService.api.CANCEL_TRIP;
        const form = [journeyId];
        return HttpRequestService.post(url, form);
    };

    return service;
}

export default {
    name: 'TripsService',
    fn: TripsService
};
