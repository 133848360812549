'use strict';

function MyPaymentsCtrl() {
    'ngInject';
}

export default {
    name: 'MyPaymentsCtrl',
    fn: MyPaymentsCtrl
};
