'use strict';

function PrivacyCtrl(AgencyInfoService, $sce, $uibModalStack, _, $log) {
    "ngInject";
    const vm = this;
    vm.privacyInfo = {
        info: []
    };

    vm.close = () => {
        $uibModalStack.dismissAll();
    };

    const defaultPrivacy = `
<div>
  <h1>
    Summary
  </h1>
  <p>
    Routematch Software, Inc. ("Routematch") is absolutely
    committed to respecting your privacy. This Privacy Policy
    describes how Routematch treats personal information when you
    access and use our website, mobile applications, and related
    products and services (collectively, the "Services"), including
    our practices for collecting, using, maintaining, and
    protecting your information. By using our Services you consent
    to this Privacy Policy. Additional terms will be available with
    the relevant Services, and those additional terms become part
    of your agreement with us if you use those Services. Please
    visit our Terms page for additional terms and conditions
    applicable to the Routematch Services. Your personal
    information is governed by this Private Policy. If you have any
    questions at all about this page or our policy, please feel
    free to send an email to support@routematch.com
  </p>
  <p>
    Our Privacy Policy explains:
  </p>
  <ul>
    <li>What information we collect and why we collect it.
    </li>
    <li>How we use that information.
    </li>
    <li>The choices we offer related to your information.
    </li>
  </ul>
  <h1>
    Information we collect
  </h1>
  <p>
    We collect information in the following ways:
  </p>
  <ul>
    <li>Information you give us. For example, many of our Services
    require you to sign up for a Routematch Account. When you do,
    we'll ask for personal information, like your name, email
    address, telephone number or credit card. We may collect
    information about the Services that you use and how you use
    them. This information includes:
      <ul>
        <li>
          <strong>Device Information</strong>
          <p>
            We may collect device-specific information (such as
            your hardware model, operating system version, unique
            device identifiers, and mobile network information
            including phone number). Routematch may associate your
            device identifiers or phone number with your Routematch
            Account.
          </p>
        </li>
        <li>
          <strong>Log Information</strong>
          <ul>
            <li style="list-style: none">When you use our Services
            or view content provided by Routematch, we may
            automatically collect and store certain information in
            server logs. This may include:
            </li>
            <li>details of how you used our service
            </li>
            <li>Internet protocol address
            </li>
            <li>device event information such as crashes, system
            activity, hardware settings, browser type, browser
            language, the date and time of your request and
            referral URL.
            </li>
            <li>cookies that may uniquely identify your browser or
            your Routematch Account.
            </li>
          </ul>
        </li>
        <li>
          <strong>Location Information</strong>
          <p>
            When you use a location-enabled Routematch service, we
            may collect and process information about your actual
            location, like GPS signals sent by a mobile device. We
            may also use various technologies to determine
            location, such as sensor data from your device that
            may, for example, provide information on nearby Wi-Fi
            access points and cell towers.
          </p>
        </li>
        <li>
          <strong>Unique application numbers</strong>
          <p>
            Certain Services include a unique application number
            such as, a Routematch or your Customer Internal ID
            issued by your transit agency. This number and
            information about your installation (for example, the
            operating system type and application version number)
            may be sent to Routematch when you install or uninstall
            that service or when that service periodically contacts
            our servers, such as for automatic updates.
          </p>
        </li>
        <li>
          <strong>Local storage</strong>
          <p>
            We may collect and store information (including
            personal information) locally on your device using
            mechanisms such as browser web storage (including HTML
            5) and application data caches.
          </p>
        </li>
        <li>
          <strong>Cookies and anonymous identifiers</strong>
          <p>
            We and our partners use various technologies to collect
            and store information when you visit a Routematch
            service, and this may include sending one or more
            cookies or anonymous identifiers to your device. We
            also use cookies and anonymous identifiers when you
            interact with Services we offer to our partners, such
            as Routematch features that may appear on other sites.
          </p>
        </li>
      </ul>
      <p>
        ROUTEMATCH MAY ALSO COLLECT ANY OTHER INFORMATION THAT THE
        SERVICES COLLECTS THAT IS DEFINED AS PERSONAL OR PERSONALLY
        IDENTIFIABLE INFORMATION UNDER AN APPLICABLE LAW
      </p>
      <h1>
        How we use information we collect
      </h1>
      <p>
        We use the information we collect from our Services to
        provide, maintain, protect and improve them, to develop new
        ones, and to protect Routematch and our users. We may also
        use this information to offer you tailored content.
      </p>
      <p>
        We may use the name you provide to Routematch across all of
        the Services we offer that require a Routematch Account. In
        addition, we may replace past names associated with your
        Routematch Account so that you are represented consistently
        across all our Services.
      </p>
      <p>
        When you contact Routematch, we may keep a record of your
        communication to help solve any issues you might be facing.
        We may use your email address to inform you about our
        Services, such as letting you know about upcoming changes
        or improvements.
      </p>
      <p>
        We use information collected from cookies and other
        technologies, like pixel tags, to improve your user
        experience and the overall quality of our Services. For
        example, by saving your location preferences, we'll be able
        to have our Services appear for the locations you prefer.
        When showing you tailored ads, we will not associate a
        cookie or anonymous identifier with sensitive categories,
        such as those based on race, religion, or sexual
        orientation.
      </p>
      <p>
        We will ask for your consent before using information for a
        purpose other than those that are set out in this Privacy
        Policy.
      </p>
      <p>
        Routematch processes personal information on our servers in
        many countries around the world. We may process your
        personal information on a server located outside the
        country where you live. Routematch complies with applicable
        territory law respecting use and storage of your personal
        information.
      </p>
      <h1>
        Transparency and choice
      </h1>
      <p>
        You may set your browser to block all cookies, including
        cookies associated with our Services, or to indicate when a
        cookie is being set by us. However, it's important to
        remember that many of our Services may not function
        properly if your cookies are disabled. For example, we may
        not remember your location preferences. For more
        information on how Routematch uses cookies and other
        tracking technologies, please read the Cookie Policy.
      </p>
      <h1>
        Cookie Policy
      </h1>
      <p>
        When you visit, access, or use our Services, we may use
        cookies and other tracking technologies to deliver and
        improve the Services, and to display relevant content,
        products, services and advertising. This Cookie Policy
        explains these technologies, including cookies, pixels, web
        beacons, and flash cookies, and how you can control them.
      </p>
      <h1>
        What is a cookie?
      </h1>
      <p>
        Cookies are small text files that are stored on your
        computer or mobile device. They are widely used in order to
        make websites work or work in a better, more efficient way.
        For example, they can recognize you and remember important
        information that will make your use of a website more
        convenient (e.g., by remembering your user preferences).
      </p>
      <h1>
        What cookies do we use?
      </h1>
      <p>
        We may use cookies and similar tools to relate your use of
        the Services to personal information obtained about you.
        All Routematch managed cookies are encrypted. Most
        Routematch cookies expire when your browser is closed,
        while some persist for up to 30 days.
      </p>
      <p>
        Below we list the different categories of cookies we may
        use on the Services.
      </p>
      <ul style="list-style-type:disc">
        <li>
          <strong>Strictly Necessary Cookies.</strong> These
          cookies are essential to the Services in order to
          facilitate our log-in process and enable you to move
          around it and to use its features. Without these cookies,
          we may not be able to provide certain services or
          features, and the Services will not perform as smoothly
          for you as we would like.
        </li>
        <li>
          <strong>Functionality Cookies.</strong> Functionality
          cookies allow us to remember the choices you make while
          browsing the Services and to provide enhanced,
          personalized content and features. For example,
          functionality cookies allow us to customize a certain
          webpage, remember if we have asked you to participate in
          a promotion, and remember other services you request,
          like watching a video or commenting on a blog. In order
          to permit your connection to the Services, our servers
          receive and record information about your computer,
          device, and browser. Such information may include your IP
          address, browser type, and other software or hardware
          information. If you access the Services from a mobile or
          other device, we may collect a unique device identifier
          assigned to that device ("UDID"), geolocation data, or
          other transactional information for that device.
        </li>
        <li>
          <strong>Security Cookies.</strong> Security cookies help
          us to enable security features to protect our users and
          site integrity.
        </li>
        <li>
          <strong>Flash Cookies.</strong> We may, in certain
          situations, use Adobe Flash Player to deliver special
          content, such as video clips or animation. To improve
          your user experience, Local Shared Objects ("Flash
          cookies") are used to provide functions such as
          remembering your settings and preferences. Flash cookies
          are stored on your device, but they are managed through
          an interface different from the one provided by your web
          browser. This means it is not possible to manage Flash
          cookies at the browser level in the same way you would
          manage cookies. Instead, you can access your Flash
          management tools from Adobe's website directly. The Adobe
          website provides comprehensive information on how to
          delete or disable Flash cookies - visit
          http://www.adobe.com/security/flashplayer for further
          information. Please be aware that if you disable or
          reject Flash cookies for the Services, you may not be
          able to access certain features such as video content or
          services that require you to sign in.
        </li>
        <li>
          <strong>Analytics Cookies.</strong> Analytics cookies,
          sometimes called performance cookies, enable us to
          understand and improve the way the Services and
          associated plugins perform. Analytics cookies collect
          information about how you use the Services, for instance,
          which pages you go to most. The information allows us to
          see the overall patterns of usage on the Services and
          help us record any difficulties you have with the
          Services.
        </li>
        <li>
          <strong>Advertising Cookies.</strong> Our Services may
          include third-party advertising and links to other
          websites. These cookies remember what you have looked at
          on and off the Services and may be combined with other
          information acquired from third parties. We may share
          this information with other organizations such as
          advertisers in order to best determine how to better
          provide you with content you may find useful based upon
          your browsing experience. Only anonymous data gathered
          from these cookies is shared with advertisers. For more
          information about third-party advertising on Routematch,
          including personalized or interest-based ads, please read
          our Advertising Policy
        </li>
        <li>
          <strong>Social Networking Cookies.</strong> These Social
          plug-in tracking cookies can be used to track both
          members and non-members of social networks for purposes
          such as behavioral advertising, analytics, and market
          research.
        </li>
      </ul>
      <h1>
        How can you control cookies?
      </h1>
      <p>
        You may refuse to accept cookies from the website at any
        time by activating the setting on your browser, which
        allows you to refuse cookies. You can refuse to accept
        Flash cookies from the website using Adobe's Flash
        management tools at
        http://www.adobe.com/security/flashplayer. Further
        information about the procedure to follow in order to
        disable cookies can be found on your Internet browser
        provider's website via your help screen. You may wish to
        refer to
        http://www.allaboutcookies.org/manage-cookies/index.html
        for information on commonly used browsers.
      </p>
      <p>
        Please note that by disabling or blocking any or all
        cookies, some features of the Services may not operate as
        intended, or you may not have access to features or
        personalization available through the Services.
      </p>
      <h1>
        Accessing and updating your personal information
      </h1>
      <p>
        Whenever you use our Services, we aim to provide you with
        access to your personal information. If that information is
        wrong, we strive to give you ways to update it quickly or
        to delete it - unless we have to keep that information for
        legitimate business or legal purposes. When updating your
        personal information, we may ask you to verify your
        identity before we can act on your request.
      </p>
      <p>
        We may reject requests that are unreasonably repetitive,
        require disproportionate technical effort (for example,
        developing a new system or fundamentally changing an
        existing practice), risk the privacy of others, or would be
        extremely impractical (for instance, requests concerning
        information residing on backup systems).
      </p>
      <p>
        Where we can provide information access and correction, we
        will do so for free, except where it would require a
        disproportionate effort. We aim to maintain our Services in
        a manner that protects information from accidental or
        malicious destruction. Because of this, after you delete
        information from our Services, we may not immediately
        delete residual copies from our active servers and may not
        remove information from our backup systems.
      </p>
      <p>
        Routematch may from time to time update this Privacy
        Policy, with the updated policy to be posted on the
        Routematch's website. The use of Personal Data provided to
        Routematch prior to the update will continue to be governed
        by the policy in effect at the time the information was
        provided. Routematch recommends that you revisit this
        Privacy Policy from time to time on a regular basis.
      </p>
      <h1>
        Access, correction, or deletion requests can be made by
        contacting Routematch Customer Support
      </h1>
      <p>
        Our Privacy Policy explains:
      </p>
      <ul style="list-style-type:disc">
        <li style="list-style: none">
          <strong>With your consent</strong>
        </li>
        <li>We will share personal information with companies,
        organizations or individuals outside of Routematch when we
        have your consent to do so. We require opt-in consent for
        the sharing of any sensitive personal information.
        </li>
        <li style="list-style: none">
          <strong>For external processing</strong>
        </li>
        <li>We provide personal information to our affiliates or
        other trusted businesses or persons to process it for us,
        based on our instructions and in compliance with our
        Privacy Policy and any other appropriate confidentiality
        and security measures.
        </li>
        <li style="list-style: none">
          <strong>For legal reasons</strong>
        </li>
        <li>We will share personal information with companies,
        organizations or individuals outside of Routematch if we
        have a good-faith belief that access, use, preservation or
        disclosure of the information is reasonably necessary to:
          <ul style="list-style-type:circle">
            <li>meet any applicable law, regulation, legal process
            or enforceable governmental request.
            </li>
            <li>enforce applicable Terms of Use, including
            investigation of potential violations
            </li>
            <li>detect, prevent, or otherwise address fraud,
            security or technical issues.
            </li>
            <li>protect against harm to the rights, property or
            safety of Routematch, our users or the public as
            required or permitted by law.
            </li>
          </ul>
        </li>
      </ul>
      <p>
        We may share aggregated, non-personally identifiable
        information publicly and with our partners - like
        publishers, advertisers or connected sites. For example, we
        may share information publicly to show trends about the
        general use of our Services
      </p>
      <p>
        If Routematch is involved in a merger, acquisition or asset
        sale, we will continue to ensure the confidentiality of any
        personal information and give affected users notice before
        personal information is transferred or becomes subject to a
        different privacy policy
      </p>
      <h1>
        Third Party Links
      </h1>
      <p>
        <strong>Routematch may include or offer third party
        products or services on our Services. These third-party
        sites have separate and independent privacy policies.
        Routematch has no responsibility or liability for the
        content and activities of these linked sites. Nonetheless,
        we seek to protect the integrity of our site and welcome
        any feedback about these sites.</strong>
      </p>
      <h1>
        Information Security
      </h1>
      <p>
        We work hard to protect Routematch and our users from
        unauthorized access to or unauthorized alteration,
        disclosure or destruction of information we hold. In
        particular:
      </p>
      <ul style="list-style-type:disc">
        <li>We take payment information through a hosted card
        capture provided by a payment card industry compliant
        payment solution provider (PSP).
        </li>
        <li>We review our information collection, storage and
        processing practices, including physical security measures,
        to guard against unauthorized access to systems.
        </li>
        <li>We restrict access to personal information to
        Routematch employees, contractors and agents who need to
        know that information in order to process it for us, and
        who are subject to strict contractual confidentiality
        obligations and may be disciplined or terminated if they
        fail to meet these obligations.
        </li>
      </ul>
      <p>
        We have implemented measures designed to secure your
        personal information from accidental loss and from
        unauthorized access, use, alteration, and disclosure. All
        information you provide to us is stored on our secure
        servers behind firewalls.
      </p>
      <p>
        Unfortunately, the transmission of information via the
        internet is not completely secure. Although we do our best
        to protect your personal information, we cannot guarantee
        the security of your personal information transmitted to
        our website. Any transmission of personal information is at
        your own risk. Routematch is not responsible for
        circumvention of any privacy settings or security measures
        contained on the website.
      </p>
      <h1>
        When this Privacy Policy applies
      </h1>
      <p>
        Our Privacy Policy applies to all of the Services offered
        by Routematch and its affiliates, including Services
        offered on other sites, but excludes Services that have
        separate privacy policies that do not incorporate this
        Privacy Policy.
      </p>
      <p>
        Our Privacy Policy does not apply to Services offered by
        other companies or individuals, including products or sites
        that may be displayed to you in search results, sites that
        may include Routematch Services, or other sites linked from
        our Services. Our Privacy Policy does not cover the
        information practices of other companies and organizations
        who advertise our Services, and who may use cookies, pixel
        tags and other technologies to serve and offer relevant
        ads.
      </p>
      <h1>
        Compliance and cooperation with regulatory authorities
      </h1>
      <p>
        We regularly review our compliance with our Privacy Policy.
        When we receive formal written complaints, we will contact
        the person who made the complaint to follow up. We work
        with the appropriate regulatory authorities, including
        local data protection authorities, to resolve any
        complaints regarding the transfer of personal data that we
        cannot resolve with our users directly.
      </p>
      <h1>
        Children and Usage
      </h1>
      <p>
        We are in compliance with the requirements of COPPA
        (Childrens Online Privacy Protection Act), we do not
        collect any information from anyone under 13 years of age.
        Our website, products and services are all directed to
        people who are at least 13 years old or older.
      </p>
      <h1>
        Changes
      </h1>
      <p>
        Our Privacy Policy may change from time to time. We will
        not reduce your rights under this Privacy Policy without
        your explicit consent. We will post any privacy policy
        changes on this page and, if the changes are significant,
        we will provide a more prominent notice (including, for
        certain Services, email notification of privacy policy
        changes).
      </p>
      <p>
        If there are any questions regarding this privacy policy,
        you may contact us using the information below:
      </p>
      <p>
        support@routematch.com 1201 West Peachtree Street NW Suite
        3300 Atlanta, GA 30309
      </p>
    </li>
  </ul>
</div>  
  `;

    // Already filtered by today's date, browser language, and device type = browser
    AgencyInfoService.getResources('privacy').then(data => {
        if (data) {
            vm.privacyInfo.info = _.map(data, function (entry) {
                return {
                    text: $sce.trustAsHtml(entry.text)
                };
            });
        } else {
            vm.privacyInfo.info = [{
                text: $sce.trustAsHtml(defaultPrivacy)
            }];
        }
    }, err => {
        $log.error(err);
    });
}

export default {
    name: 'PrivacyCtrl',
    fn: PrivacyCtrl
};
