'use strict';

function MenuToggleDirective($timeout, $window, menu) {
    "ngInject";
    return {
        scope: {
            section: '='
        },
        templateUrl: 'templates/menu-toggle.html',
        link: ($scope, $element) => {
            const controller = $element.parent().controller();
            $scope.isOpen = () => {
                return controller.isOpen($scope.section);
            };
            $scope.toggle = () => {
                controller.toggleOpen($scope.section);
            };
            $scope.isShowNav = () => {
                return controller.isShowNav();
            };
            $scope.changeMenuItemToActive = (section) => {
                menu.setOpenedSection(section);
            };
            $scope.uriClick = (uri) => {
                $window.location.href = uri;
            };
            $scope.getSelectedSectionName = () => {
                return angular.isDefined(menu.selectedSection()) ? menu.selectedSection().name : '';
            };
   
        }
    };
}

export default {
    name: 'menuToggle',
    fn: MenuToggleDirective
};
