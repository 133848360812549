'use strict';
const dbc = require('designbycontract');

module.exports = function(cb) {
    dbc.requireWithMessage(arguments.length === 1, 'function called with incorrect number of arguments');
    dbc.requireWithMessage(dbc.isFunction(cb), 'Callback must be provided');

    const request = require('request-promise');

    const options = {
        uri: require('./infoUrl.json').url + '/allagencies/worldwide/v2/agencies',
        method: 'GET',
        json: true
    };

    request(options).then(function(result) {
        return cb(null, result.allAgencies);
    }).catch(cb);
};
