'use strict';

import angular from 'angular';
import 'angular-ui-router';
import './templates';
import './filters';
import './controllers';
import './services';
import './directives';
import './factories';
import onConfig  from './on_config';
import onRun     from './on_run';
import deferredBootstrapper from 'angular-deferred-bootstrap';
import 'angular-animate';
import 'angular-ui-bootstrap';
import 'angular-google-analytics';
import 'angular-smart-table';
import 'v-accordion';
import 'angular-socket-io';
import './const';
import './version';
import 'angular-messages';
import 'ng-idle';
import 'angular-md5';
import 'angular-credit-cards';
import 'querystring';
import 'angular-uuid4';
import 'ng-file-upload';
import 'moment';
import 'moment-timezone';
import 'angular-moment';
import 'angular-esri-map';

const requires = [
    'ui.router',
    'templates',
    'app.filters',
    'app.controllers',
    'app.services',
    'app.directives',
    'app.factories',
    'app.const',
    'app.version',
    'angularMoment',
    'vAccordion',
    'btford.socket-io',
    'ngMessages',
    'ngIdle',
    'ngMd5',
    'credit-cards',
    'uuid4',
    'ngFileUpload',
    'ui.bootstrap',
    'ngAnimate',
    'smart-table',
    'esri.map',
    'esri.core',
    'angular-google-analytics'
];

const CONFIGURATION_LOCATION = 'seed/configuration.json';
window.app = angular.module('app', requires);

angular.module('app').config(onConfig);
angular.module('app').run(onRun);

deferredBootstrapper.bootstrap({
  module: 'app',
  element: document,
  strictDi: true,
  resolve: {
    APP_CONFIG: ['$http', ($http) => {
      return $http.get(CONFIGURATION_LOCATION)
      .then( response => {
        return response.data;
      })
      .catch( err => {
        console.error(err.message);
      });
    }]
  }
});
