'use strict';

function AuthFactory($rootScope, APP_CONFIG, JWTService, _, Idle) {
    'ngInject';

    const service = {};

    let role = APP_CONFIG.USER_ROLES.anon;

    service.setUserRole = (userRole) => {
        switch (userRole) {
        case 'auth':
            Idle.watch();
            role = APP_CONFIG.USER_ROLES.auth;
            break;
        default:
            role = APP_CONFIG.USER_ROLES.anon;
        }
    };

    service.isRider = () => {
        if (!_.isNil(JWTService.getToken())) {
            if (_.isEqual(JWTService.getUserType(), APP_CONFIG.USER_TYPES.rider)) {
                return true;
            }
        }
        return false;
    };


    service.isFacility = () => {
        if (!_.isNil(JWTService.getToken())) {
            if (_.isEqual(JWTService.getUserType(), APP_CONFIG.USER_TYPES.facility)) {
                return true;
            }
        }
        return false;
    };

    //hardcoding in 'administrator here', change if appropriate
    service.isAdministrator = () => {
        if (!_.isNil(JWTService.getToken())) {
            if (_.isEqual(JWTService.getUserType(), 'administrator')) {
                return true;
            }
        }
        return false;
    };

    service.isAuthorized = (accessRoles) => {
        if (!angular.isArray(accessRoles)) {
            accessRoles = [accessRoles];
        }
        if (JWTService.getToken() !== null) {
            service.setUserRole(JWTService.getUserRole());
        } else {
            role = APP_CONFIG.USER_ROLES.anon;
        }
        return  _.includes(accessRoles, role);
    };

    return service;
}

export default {
    name: 'Auth',
    fn: AuthFactory
};
