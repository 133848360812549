'use strict';

function FormatDateFilter(moment, $window) {
    'ngInject';

    return (dateString) => {
        const date = moment(dateString, 'YYYY-MM-DD').toDate();
        const currentLocaleData = $window.navigator.language;
        const zone =  moment().format('Z');
        if (zone === "+10:00" || zone === "+09:00" || zone === "+11:00"){
            return moment(dateString, 'YYYY-MM-DD').format('DD/MM/YYYY');
        }
        else {
            return date.toLocaleDateString(currentLocaleData);
        }
    };
}

export default {
    name: 'formatdate',
    fn: FormatDateFilter
};
