'use strict';


function ContactCtrl(AgencyInfoService, AgencyService, $sce, _, $log) {
    "ngInject";

    const vm = this;

    vm.contactInfo = {
        info: [],
        template: ''
    };

    const agency = AgencyService.getAgency();

    // Already filtered by today's date, browser language, and device type = browser
    AgencyInfoService.getResources('contact').then(data => {
        if (data) {
            vm.contactInfo.info = _.map(data, function (entry) {
                return {
                    text: $sce.trustAsHtml(entry.text)
                };
            });
        } else {
            vm.contactInfo.template = AgencyInfoService.contact.customTemplate ? `templates/custom/${agency}_contact.html` : "templates/default/contact.html";
        }
    }, err => {
        $log.error(err);
    });

    if (angular.isDefined(AgencyInfoService.contact.address) && angular.isDefined(AgencyInfoService.contact.address.length)) {
        for (let i = 0; i < AgencyInfoService.contact.address.length; i++) {
            AgencyInfoService.contact.address[i] = $sce.trustAsHtml("" + AgencyInfoService.contact.address[i]);
        }
    }

    if (angular.isDefined(AgencyInfoService.contact.email) && angular.isDefined(AgencyInfoService.contact.email.length)) {
        for (let i = 0; i < AgencyInfoService.contact.email.length; i++) {
            AgencyInfoService.contact.email[i] = $sce.trustAsHtml("" + AgencyInfoService.contact.email[i]);
        }
    }


    vm.contactInfo = {
        isEmpty: AgencyInfoService.policy.isEmpty,
        contacts: AgencyInfoService.contact.address,
        showAll: AgencyInfoService.showFullContact.showFullContact,
        email: AgencyInfoService.contact.email,
        title: $sce.trustAsHtml(AgencyInfoService.contact.title),
        sec1: $sce.trustAsHtml(AgencyInfoService.contact.sec1),
        sec2: $sce.trustAsHtml(AgencyInfoService.contact.sec2),
        sec1phone: $sce.trustAsHtml(AgencyInfoService.contact.sec1phone),
        sec2phone: $sce.trustAsHtml(AgencyInfoService.contact.sec2phone),
        customsec: $sce.trustAsHtml(AgencyInfoService.contact.customsec),
        website: $sce.trustAsHtml(AgencyInfoService.contact.website)
    };



    vm.checkContactInfo = () => {
        return !Object.keys(vm.contactInfo).length;
    };


    vm.getContactTemplate = () => {
        return vm.contactInfo.template;
    };
}

export default {
    name: 'ContactCtrl',
    fn: ContactCtrl
};
