'use strict';

function TermsConditionsCtrl(AgencyInfoService, $sce, $uibModalStack, _, $log) {
    "ngInject";
    const vm = this;
    vm.termsInfo = {
        info: []
    };

    vm.close = () => {
        $uibModalStack.dismissAll();
    };

    const defaultTerms = `
<div>
  <h2>
    Summary
  </h2>
  <p>
    These terms and conditions (the "Terms") set forth the entire
    agreement between you (also referred to as the "User" herein)
    and Routematch (also referred to as "we" herein) regarding your
    use of this mobile application (the "App"). Use or downloading
    of the App is conditioned on acceptance of the terms and
    conditions of this agreement. By using or downloading the App,
    you agree to such terms and conditions. We recommend you read
    these Terms carefully. We may modify these Terms at any time by
    posting revised Terms.
  </p>
  <h2>
    Your Account
  </h2>
  <p>
    You must set up a user account to use the App. If required by
    it, the transit agency you use shall be responsible for
    providing you with your internal 'ID' and may do so by mail, in
    person, email, text message or any other method in the transit
    agency's sole discretion. All information submitted by you to
    create your user account will be maintained by Routematch in
    accordance with its Privacy Policy. Should you wish to
    de-activate your account, please contact the individual transit
    agency whom you created your account with who will instruct
    Routematch to do so.
  </p>
  <h2>
    Use of the App / AUP
  </h2>
  <p>
    Routematch grants you the right to download, install and use
    the mobile application on your mobile handset only for your
    personal access information in accordance with these terms and
    conditions. You do not and will not own the mobile application
    or any information that is provided to you through it or
    Routematch, but you may use the application with these terms
    and conditions for the purposes reasonably intended by the
    parties. The mobile application is provided to you free of
    charge. only available so long as the transit agency for which
    you use this App is a customer of Routematch.
  </p>
  <p>
    Routematch cannot guaranty that the App will work with every
    device or operating system, be suitable for your needs, or that
    it will work as anticipated in every configuration. You may not
    modify, copy, publish, license, sell, or otherwise
    commercialize this App or any information or software
    associated with this App. You may not rent, lease or otherwise
    transfer rights to this App. You may not use this App in any
    manner that could impair any transit agency's sites in any way
    or interfere with any party's use or enjoyment of any transit
    agency's sites, including any use of this App that imposes or
    may impose an unreasonable or disproportionately large load on
    the transit agency's infrastructure. You must use the App in
    compliance with all applicable laws. You must comply with
    applicable third-party terms of agreement when using this App
    (e.g. your wireless data service agreement). Your right to use
    this Application and your User Account is subject to immediate
    termination if you violate any provision of these Terms or any
    of the transit agency's rules, regulations, terms and
    conditions.
  </p>
  <h2>
    Other Terms
  </h2>
  <p>
    Routematch may change these Terms from time to time. When
    Routematch does so, Routematch will publish the new version of
    the Terms in the App. Each new version of these Terms will take
    effect from the time it is first published in the App, and
    apply to your use of the App from that point forward.
    Routematch cannot accept any liability for any oral statement
    or representation regarding the App, whether made by us or a
    third party. If any provision of these Terms is found to be
    invalid by a court of law then the remainder of these Terms
    shall continue to apply to the greatest extent possible,
    excluding the unenforceable provision(s). You agree that no
    joint venture, partnership, employment, or agency relationship
    exists between you and Routematch as a result of these Terms or
    your use of the App. You and Routematch agree that the law of
    the United States and of the State of Georgia applies in
    interpreting these Terms and to any transaction consummated by
    or through the App. Any dispute between you and Routematch
    regarding the App or arising out of or in connection with these
    Terms may only be heard by United States Federal or Routematch
    State courts located in Georgia.
  </p>
  <h2>
    Date Charges on Your Device
  </h2>
  <p>
    The App requires a correctly configured and functional wireless
    internet data connection (either cellular or wifi), both for
    the initial installation, and for the display of trip
    information. The App is free, but You will incur data charges
    for the use of the App depending on your individual agreement
    with your wireless data service provider. You are responsible
    for any such costs. Note that if you are using the App on a
    non-domestic network, the cost of data usage may be
    considerably higher than that incurred when you are in your
    home country. Routematch is unable to provide any warranties as
    to the levels of connectivity you will receive via your mobile
    device. Your levels of connectivity depend upon your tariff,
    your wireless data service provider or your corporate policy if
    you have a work-issued device. Routematch does not accept
    responsibility for any connectivity issues you may experience.
    For this reason, you are encouraged to utilize the App in
    advance of your need in an area with adequate signal coverage.
    Please contact your network provider or visit their website if
    you require assistance configuring a data connection for your
    device.
  </p>
  <h2>
    Updates and Availability
  </h2>
  <p>
    From time to time, Routematch may issue updates to the App, in
    which case you may not be able to continue to use the version
    of the App installed on your mobile device; you will be
    required to download and install the relevant update.
    Routematch may suspend access to the App. Routematch can do
    this for any reason, but will usually only do so when carrying
    out maintenance on the App or the systems supporting it.
    Routematch will always attempt to provide you with reasonable
    notice of any such suspension.
  </p>
  <h2>
    Limitation of Liability
  </h2>
  <p>
    In no event will Routematch or the individual transit agencies
    be liable for any direct, indirect, special, punitive,
    exemplary or consequential losses or damages of whatsoever kind
    arising out of your use or access to the App, including loss of
    profit or the like whether or not in the contemplation of the
    parties, whether based on breach of warranty, contract, tort
    (including negligence), product liability or otherwise.
    Routematch is not liable to you for any damage or alteration to
    your equipment including but not limited to computer equipment,
    handheld device or mobile telephones as a result of the
    installation or use of the App. This limitation applies whether
    damages arise from use or misuse of and reliance on this App,
    from inability to use this App, or from the interruption,
    suspension, or termination of this App (including any damages
    incurred by third parties); and applies notwithstanding a
    failure of the essential purpose of any limited remedy and to
    the fullest extent permitted by law. Nothing in these terms and
    conditions shall exclude or limit Routematch's liability for
    death or personal injury caused by negligence or for fraud or
    fraudulent misrepresentation or any other liability which
    cannot be excluded or limited under applicable law.
  </p>
  <h2>
    Disclaimer of Warranties
  </h2>
  <p>
    THIS APPLICATION AND THE SERVICE(S) ACCESSED BY IT, AS WELL AS
    ANY LINKS TO THIRD-PARTY LINKED SITES (AS DEFINED BELOW) ARE
    PROVIDED "AS IS" AND "AS AVAILABLE". ROUTEMATCH EXPRESSLY
    DISCLAIMS TO THE FULLEST EXTENT PERMITTED BY LAW ALL EXPRESS,
    IMPLIED AND STATUTORY WARRANTIES, INCLUDING WITHOUT LIMITATION
    THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
    PURPOSE AND NON-INFRINGEMENT OF PROPRIETARY RIGHTS, AND ANY
    WARRANTIES REGARDING THE SECURITY, RELIABILITY, TIMELINESS AND
    PERFORMANCE OF THIS APP. You download and use this App at your
    own discretion and risk, and you are solely responsible for any
    damages to your hardware device(s) or loss of data that results
    from the download or use of this App.
  </p>
  <h2>
    Indemnification
  </h2>
  <p>
    You will indemnify and hold harmless Routematch and the
    individual transit agencies and their officers, board members,
    agents and employees, from any claim made by any third-party
    arising in connection with a violation of these Terms for which
    you are responsible. The individual transit agencies reserve
    the right, at their own expense, to assume the exclusive
    defense and control of any matter subject to indemnification by
    you, but doing so will not excuse your indemnity obligations.
  </p>
  <h2>
    Security
  </h2>
  <p>
    The App will store certain personal information on your mobile
    device when you create your user profile. You should ensure
    that your mobile device is appropriately protected so that your
    stored details cannot be used or accessed by third parties.
    Please refer to our Private Policy on how we protect your
    information.
  </p>
  <h2>
    Hyperlinking
  </h2>
  <p>
    This App may provide a link to other sites by allowing the user
    to leave this site to access third-party material or by
    bringing the third-party material into this site via "inverse"
    hyperlinks and framing technology (a "Linked Site"). Routematch
    has no discretion to alter, update, or control the content on a
    Linked Site. The fact that Routematch has provided a link to a
    site is not an endorsement, authorization, sponsorship, or
    affiliation with respect to such site, its offerings, its
    owners, or its providers. There are inherent risks in relying
    upon, using or retrieving any information found on the
    internet, and Routematch urges you to make sure you understand
    these risks before relying upon, using, or retrieving any such
    information on a Linked Site.
  </p>
</div>  
  `;

    // Already filtered by today's date, browser language, and device type = browser
    AgencyInfoService.getResources('terms').then(data => {
        if (data) {
            vm.termsInfo.info = _.map(data, function (entry) {
                return {
                    text: $sce.trustAsHtml(entry.text)
                };
            });
        } else {
            vm.termsInfo.info = [{
                text: $sce.trustAsHtml(defaultTerms)
            }];
        }
    }, err => {
        $log.error(err);
    });

}

export default {
    name: 'TermsConditionsCtrl',
    fn: TermsConditionsCtrl
};
