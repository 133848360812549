'use strict';

function PasswordService(HttpRequestService, ConfigService) {
    'ngInject';

    const service = {};

    service.changePass = (userValues) => {
        const form = {
            passwordOld: userValues.oldPassword,
            passwordNew: userValues.newPassword,
            passwordConfirm: userValues.confirmPassword
        };
        return HttpRequestService.patch(ConfigService.api.CHANGE_PASSWORD + userValues.user_id, form);
    };


    return service;
}

export default {
    name: 'PasswordService',
    fn: PasswordService
};
