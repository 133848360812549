'use strict';
const dbc = require('designbycontract');

module.exports = function(agencyAbbreviation, cb) {
    dbc.requireWithMessage(arguments.length === 2, 'function called with incorrect number of arguments');
    dbc.requireWithMessage(dbc.isString(agencyAbbreviation), 'Agency Abbreviation must be provided');
    dbc.requireWithMessage(dbc.isFunction(cb), 'Callback must be provided');

    const axios = require('axios');
    const url = require('./infoUrl.json').url + '/agencies/v2/' + agencyAbbreviation;
    axios.request({
      url: url,
      method: 'get',
    }).then(result => {
        return cb(null, result.data);
    }).catch(cb);
    /*
    axios.get(url).then(function (result) {
        return cb(null, result.data);
    }).catch(cb);
    */
};
