'use strict';

function LogoutFactory($rootScope, APP_CONFIG, $state, Idle, $window, ConfigService, AgencyService) {
    'ngInject';

    const factory = {};

    const timeoutMsg = APP_CONFIG.sign_out_msg.time_out;
    const signOutMsg = APP_CONFIG.sign_out_msg.logout;

    $rootScope.$on('IdleTimeout', function() {
        factory.logout('timeout');
    });

    factory.logout = (action) => {
        if (typeof Idle !== 'undefined') {
            Idle.unwatch();
        }
        switch (action) {
        case 'signout':
            $rootScope.signOutMsg = signOutMsg;
            $rootScope.timeoutMsg = false;
            break;
        case 'timeout':
            $rootScope.signOutMsg = signOutMsg;
            $rootScope.timeoutMsg = timeoutMsg;
            break;
        }

        const agency = AgencyService.getAgency();
        localStorage.clear();
        $window.ga('remove');
        $state.go('RegLogin.login', {a: agency});
    };

    return factory;
}

export default {
    name: 'Logout',
    fn: LogoutFactory
};
