'use strict';

function IEHelperService() {
    'ngInject';

    const service = {};

    service.findInArray = function(array, argumentTitle, checkable){
        const length = array.length;
        let index = 0;
        let position = -1;
        for (index; index < length; index++) {
            if (argumentTitle !== null){
                if ((array[index])[argumentTitle] === checkable){
                    position = parseInt(index);
                    index = length;
                } 
            } else {
                if (array[index] === checkable){
                    position = parseInt(index);
                    index = length;
                } 
            }
        }
        return position;
    };


    return service;

}

export default {
    name: 'IEHelperService',
    fn: IEHelperService
};
