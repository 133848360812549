'use strict';

function RmxsService($http, ConfigService, HttpRequestService) {
    'ngInject';

    const service = {};

    service.getFavAddrs = (rmid) => {
        const getFavAddrsUrl = ConfigService.api.GET_RMXS_ADDR + rmid + "/favouriteAddresses";
        return HttpRequestService.get(getFavAddrsUrl);
    };

    service.getSuspendedDateRange = (rmid, startDate, endDate) => {
        const getSuspendedDateRangeUrl = ConfigService.api.GET_RMXS_ADDR + rmid + "/suspendedDateRange" + "?startDate=" + encodeURIComponent(startDate) + "&endDate=" + encodeURIComponent(endDate);
        return HttpRequestService.get(getSuspendedDateRangeUrl);
    };

    service.getMobilityType = (rmid) => {
        const getMobilityTypeUrl = ConfigService.api.GET_RMXS_ADDR + rmid + "/mobilityType";
        return HttpRequestService.get(getMobilityTypeUrl);
    };

    service.getMobilityTypeList = () => {
        const getMobilityTypeListUrl = ConfigService.api.GET_MOBILITY_TYPES;
        return HttpRequestService.get(getMobilityTypeListUrl);
    };

    service.getTripPurposeList = () => {
        const getTripPurposeListUrl = ConfigService.api.GET_TRIP_PURPOSES;
        return HttpRequestService.get(getTripPurposeListUrl);
    };

    service.getAttendantCount = (rmid) => {
        const getAttendantCountUrl = ConfigService.api.GET_RMXS_ADDR + rmid + "/attendantCount";
        return HttpRequestService.get(getAttendantCountUrl);
    };

    service.getServiceId = (rmid) => {
        const getServiceIdUrl = ConfigService.api.GET_RMXS_ADDR + rmid + "/serviceId";
        return HttpRequestService.get(getServiceIdUrl);
    };

    service.getRidesWithinDates = (rmid, startDate, endDate) => {
        const getRidesWithinRangeUrl = ConfigService.api.GET_RMXS_ADDR + rmid + ConfigService.api.GET_RIDES + "?startDate=" + encodeURIComponent(startDate) + "&endDate=" + encodeURIComponent(endDate);
        return HttpRequestService.get(getRidesWithinRangeUrl);
    };

    service.getTripPurpose = () => {
        const getTripPurposeUrl = ConfigService.api.GET_RMXS + "/api/tripPurposes";
        return HttpRequestService.get(getTripPurposeUrl);
    };

    //RID-2795: Only provide the book a trip functionality if the settings are adhered to (Rider Portal)
    service.getDefaultServiceInfo = (rmid) => {
        const getDefaultServiceInfoUrl = ConfigService.api.GET_RMXS + `/api/v5/riders/${rmid}/defaultService`;
        return HttpRequestService.get(getDefaultServiceInfoUrl);
    };

    return service;
}

export default {
    name: 'RmxsService',
    fn: RmxsService
};
