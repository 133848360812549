'use strict';

function VersionInfoService(VersionInfo) {
    'ngInject';

    const service = {};

    service.version   = VersionInfo.version + '-' + VersionInfo.revision + '-' + VersionInfo.timestamp;
    service.nickname  = VersionInfo.nickname;
    return service;
}

export default {
    name: 'VersionInfoService',
    fn: VersionInfoService
};
