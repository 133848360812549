'use strict';

function MyAccountDetailsCtrl($scope, $window, RmxsService, JWTService, CustomerService, PasswordService) {
    'ngInject';
    const vm = this;
    vm.pageParams = {
        detailsLoaded: false
    };

    vm.editFlag = false;
    vm.user = {
        user_id : JWTService.getUserId(),
        oldPassword : null,
        newPassword : null,
        confirmPassword : null
    };
    vm.passwordSave = false;
    vm.passwordSaveError = false;


    vm.changePassword = (oldPassword, newPassword, confirmPassword) => {
        vm.passwordSave = false;
        vm.passwordSaveError = false;
        vm.editFlag = !vm.editFlag;
        vm.user.oldPassword = oldPassword;
        vm.user.newPassword = newPassword;
        vm.user.confirmPassword = confirmPassword;

        if (vm.user.oldPassword !== null && vm.user.newPassword !== null && vm.user.confirmPassword !== null && vm.editFlag === false){
            PasswordService.changePass(vm.user)
                .then((res) => {
                    if (res.n_changed ===  1){
                        vm.passwordSave = true;
                    }
                }).catch(() => {
                    vm.passwordSaveError = true;
                });
        }
        vm.oldPassword = null;
        vm.newPassword = null;
        vm.confirmPassword = null;
    };

    vm.cancel = () => {
        if (vm.editFlag === true){
            vm.editFlag = !vm.editFlag;
        }
    };

    vm.accountDet = {
        password: {
            key: "Mobility Account Password",
            value: "*****"
        },
        firstname: {
            key: "Firstname",
            value: ""
        },
        lastname: {
            key: "Lastname",
            value: ""
        },
        address: {
            key: "Address",
            value: ""
        },
        telephone: {
            key: "Telephone",
            value: 0
        }
    /*,
        agency: {
          key: "agency",
          value: ""
        }*/
    };


    const getCustomerDetail = () => {
        const rmidToPass = JWTService.getRmid();

        CustomerService.getCustomer(rmidToPass).then((res) => {
            vm.accountDet.firstname.value = res.forename;
            vm.accountDet.lastname.value = res.surname;
            vm.accountDet.address.value = res.address + ", " + res.city + ", " + res.state;
            vm.accountDet.telephone.value = res.homephone;
            vm.pageParams.detailsLoaded = true;
        });

    };

    getCustomerDetail();

}

export default {
    name: 'MyAccountDetailsCtrl',
    fn: MyAccountDetailsCtrl
};
