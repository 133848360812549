'use strict';

function mapModulesSvc(esriLoader, $q) {
    'ngInject';
    const service = {};

    let Map, Point, PictureMarkerSymbol, Graphic, GraphicLayer, Polyline,
        SimpleLineSymbol, SpatialReference, InfoTemplate, Color,
        Polygon, SimpleFillSymbol, Draw;
    service.loadEsriSDK = () => {
        if (!esriLoader.isLoaded()) {
            return esriLoader.bootstrap({
                url: 'https://js.arcgis.com/3.20/'
            });
        } else {
            return $q.resolve();
        }
    };

    service.loadModules = (modules) => {
        return esriLoader.require(modules);
    };

    service.setMap = (map) => {
        Map = map;
    };

    service.getMap = (mapDiv, basemap, center) => {
        return new Map(mapDiv, {
            basemap: basemap,
            center: center,
            ui: {
                components: ['attribution']
            }
        });
    };


    service.setPoint = (point) => {
        Point = point;
    };

    service.getPoint = () => {
        return Point;
    };

    service.setPolyline = (polyline) => {
        Polyline = polyline;
    };

    service.getPolyline = () => {
        return Polyline;
    };

    service.setSimpleLineSymbol = (simpleLine) => {
        SimpleLineSymbol = simpleLine;
    };

    service.getSimpleLineSymbol = () => {
        return SimpleLineSymbol;
    };

    service.setPictureMarkerSymbol = (pms) => {
        PictureMarkerSymbol = pms;
    };

    service.getPictureMarkerSymbol = () => {
        return PictureMarkerSymbol;
    };

    service.setGraphic = (gr) => {
        Graphic = gr;
    };

    service.getGraphic = () => {
        return Graphic;
    };

    service.setGraphicLayer = (gl) => {
        GraphicLayer = gl;
    };

    service.getGraphicLayer = () => {
        return new GraphicLayer();
    };

    service.setSpatialRef = (sr) => {
        SpatialReference = sr;
    };

    service.getSpatialRef = () => {
        return SpatialReference;
    };

    service.setInfoTemp = (infoTemp) => {
        InfoTemplate = infoTemp;
    };

    service.getInfoTemp = () => {
        return InfoTemplate;
    };

    service.setPolygon = (plg) => {
        Polygon = plg;
    };

    service.getPolygon = () => {
        return Polygon;
    };
    service.setSimpleFillSymbol = (sfs) => {
        SimpleFillSymbol = sfs;
    };
    service.getSimpleFillSymbol = () => {
        return SimpleFillSymbol;
    };

    service.setColor = (color) => {
        Color = color;
    };

    service.getColor = () => {
        return Color;
    };

    service.setDraw = (draw) => {
        Draw = draw;
    };

    service.getDraw = () => {
        return Draw;
    };

    return service;
}

export default {
    name: 'mapModulesSvc',
    fn: mapModulesSvc
};
