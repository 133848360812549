'use strict';

const LOCAL_PATH = 'seed/';

function HttpIntercepterFactory($q, $injector, JWTService, $log, APP_CONFIG) {
    'ngInject';

    const service = {};

    this.tokenGetter = () => {
        const token = localStorage.getItem('token');

        if (JWTService.isExpired()) {
            console.log('=>>>>>', APP_CONFIG.api.get_refresh_token);
            return $injector.get('$http').get(APP_CONFIG.api.get_refresh_token, {
                skipAuthorization: true
            }).then((response) => {
                const token = response.data.token;
                JWTService.setToken(token);
                return token;
            });
        } else {
            return token;
        }
    };

    service.request = (config) => {
        if (config.cache) {
            return config;
        }
        if (config.skipAuthorization) {
            return config;
        }

        var tokenPromise;
        if ( config.url.startsWith(LOCAL_PATH) ) {
          tokenPromise = Promise.resolve(null);
        } else {
          tokenPromise = $q.when($injector.invoke(this.tokenGetter, this, {
            config: config
          }));
        }

        return tokenPromise.then((token) => {
            if (token) {
                config.headers.Authorization = 'Bearer: ' + token;
            }
            return config;
        });
    };

    service.responseError = (rejection) => {
        if (rejection.status === 401) {
            $injector.get('$state').go('Login');
        }

        return $q.reject(rejection);
    };

    return service;
}

export default {
    name: 'HttpIntercepterFactory',
    fn: HttpIntercepterFactory
};
