'use strict';

function PhoneInputDirective($filter, $browser) {
    "ngInject";

    return {
        require: 'ngModel',
        link: (scope, elm, attr, ctrl) => {
            const listener = function() {
                const value = elm.val();
                elm.val($filter('phoneInput')(value));
            };

            // This runs when the model gets updated on the scope directly and keeps our view in sync
            ctrl.$render = function() {
                elm.val($filter('phoneInput')(ctrl.$viewValue));
            };

            elm.bind('change', listener);
            elm.bind('keydown', function(event) {
                const key = event.keyCode;
                // If the keys include the CTRL, SHIFT, ALT, or META keys, or the arrow keys, do nothing.
                // This lets us support copy and paste too
                if (key === 91 || (15 < key && key < 19) || (37 <= key && key <= 40)) {
                    return;
                }
                $browser.defer(listener); // Have to do this or changes don't get picked up properly
            });

            elm.bind('paste cut', function() {
                $browser.defer(listener);
            });
        }
    };
}

export default {
    name: 'phoneInput',
    fn: PhoneInputDirective
};
