'use strict';

function OnConfig($stateProvider, $locationProvider, $urlRouterProvider,
    $httpProvider, APP_CONFIG, IdleProvider, AnalyticsProvider) {
    'ngInject';

    if (APP_CONFIG.ga_tracking_id) {
        console.log('GA activated');
        AnalyticsProvider.setAccount(APP_CONFIG.ga_tracking_id);
    } else {
        console.log('GA inactive');
    }

    $locationProvider.html5Mode({
        enabled: true,
        requireBase: false
    });

    $stateProvider
        .state('RegLogin', {
            abstract: true,
            templateUrl: 'login-partial.html',
            controller: 'LoginPartialCtrl as loginPartial',
            resolve: {
                configBaseUrl: (AgencyResource, $location) => {
                    return AgencyResource.configBaseUrl($location.search().a).then(() => {
                        return true;
                    }).catch(() => {
                        return false;
                    });
                }
            }
        })
        .state('RegLogin.register', {
            url: '/register?a',
            templateUrl: 'register.html',
            controller: 'RegisterCtrl as register',
            resolve: {
                configBaseUrl: (AgencyResource, $location) => {
                    return AgencyResource.configBaseUrl($location.search().a).then(() => {
                        return true;
                    }).catch(() => {
                        return false;
                    });
                }
            }
        })
        .state('RegLogin.login', {
            url: '/login?a',
            templateUrl: 'login.html',
            controller: 'LoginCtrl as login',
            title: 'Login',
            resolve: {
                configBaseUrl: (AgencyResource, $location) => {
                    return AgencyResource.configBaseUrl($location.search().a).then(() => {
                        return true;
                    }).catch(() => {
                        return false;
                    });
                }
            }
        })
        .state('Rider', {
            abstract: true,
            templateUrl: 'rider-partial.html',
            controller: 'RiderCtrl as rider',
            title: 'Rider',
            data: {
                access: APP_CONFIG.USER_ROLES.auth
            }
        })
        .state('Rider.home', {
            url: '/',
            templateUrl: 'rider-dashboard.html',
            title: 'Rider Dashboard',
            controller: 'DashboardCtrl as dashboard'
        })
        .state('Rider.BookTrip', {
            url: '/booktrip',
            controller: 'BookTripCtrl as bookTrip',
            templateUrl: 'book-trip.html',
            title: 'Book a Trip'
        })
        .state('Rider.listTrip', {
            url: '/listTrip',
            controller: 'MyTripsCtrl as myTrips',
            templateUrl: 'my-trips.html',
            title: 'Trips List'
        })
        .state('Rider.viewTrip', {
            url: '/viewtrip?journeyid',
            params: {
                trip: null
            },
            controller: 'ViewTripCtrl as viewTrip',
            templateUrl: 'view-trip.html',
            title: 'View a Trip'
        })
        .state('Rider.viewNextTrip', {
            url: '/viewNextTrip?journeyid',
            params: {
                trip: null
            },
            controller: 'ViewNextTripCtrl as viewNextTrip',
            templateUrl: 'view-next-trip.html',
            title: 'viewNextTrip',
            resolve: {
                requiresTrip: ($q, NextTripService, DialogService, _) => {
                    const deferred = $q.defer();
                    NextTripService.getNextTrip().then((trip) => {
                        if (_.isNull(trip) || _.isUndefined(trip) || trip.length === 0) {
                            DialogService.openNoNextTripModal();
                            deferred.resolve();
                        } else {
                            deferred.resolve();
                        }
                    });
                    // return deferred.promise;
                }
            }
        })
        .state('Rider.myAccount', {
            url: '/myAccount',
            views: {
                '': {
                    controller: 'MyAccountCtrl as myAccount',
                    templateUrl: 'my-account.html'
                },
                'riderInfoCard@Rider.myAccount': {
                    controller: 'MyAccountInfoCardCtrl as myAccountInfo',
                    templateUrl: 'templates/customer-profile-info-card.html'
                },
                'riderAccDetails@Rider.myAccount': {
                    controller: 'MyAccountDetailsCtrl as accountsDetail',
                    templateUrl: 'templates/customer-profile-account-details.html'
                }
            }
        })
        .state('Rider.notifications', {
            url: '/notifications',
            controller:'NotificationsCtrl as notifications',
            templateUrl: 'notifications.html',
            title: 'Notifications'
        })
        .state('Rider.search', {
            url: '/search',
            controller:'SearchCtrl as search',
            templateUrl: 'search.html',
            title: 'search'
        })
        .state('Rider.myPayments', {
            url: '/myPayments',
            controller:'MyPaymentsCtrl as myPayments',
            templateUrl: 'my-payments.html',
            title: 'My Payments'
        })
        .state('Rider.terms', {
            url: '/terms',
            controller: 'TermsConditionsCtrl as termsAndConditions',
            templateUrl: 'terms-and-conditions.html',
            title: 'Terms'
        })
        .state('Rider.policies', {
            url: '/policies',
            controller: 'PrivacyCtrl as privacy',
            templateUrl: 'privacy-policy.html',
            title: 'Policies'
        })
        .state('Rider.privacy', {
            url: '/privacy',
            redirectTo: 'Rider.policies'
        })
        .state('Rider.settings', {
            url: '/settings',
            controller:'SettingsCtrl as settings',
            templateUrl: 'settings.html',
            title: 'Settings'
        })
        .state('Rider.contact', {
            url: '/contact',
            controller: 'ContactCtrl as contact',
            templateUrl: 'contact-page.html',
            title: 'Contact Us'
        })
        .state('Rider.about', {
            url: '/about',
            controller: 'AboutCtrl as about',
            templateUrl: 'about-page.html',
            title: 'About'
        });

    $httpProvider.interceptors.push('HttpIntercepterFactory');
    IdleProvider.idle(APP_CONFIG.TIMEOUT_DURATION);
    $urlRouterProvider.otherwise('/');
}

export default OnConfig;
