'use strict';
const errors = require('./errors.json');
module.exports = function(code, extra) {  
    const er = errors[code];

    if (!er) {
    // either throw an error, or return an "unknown" error
        throw new ReferenceError('Unknown error code: ' + code);
    }

    const err = new Error(er.message);
    err.code = code;
    err.description = er.description;
    err.status = er.status || 500;

    if (extra) {
        err.extra = extra;
    }

    // err.formatted is an object that can be sent to the user
    err.formatted = {
        code: err.code,
        message: err.message,
        description: err.description,
        status: err.status,
        extra: err.extra
    };

    return err;
};
