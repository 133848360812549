'use strict';

function ClientAppFeaturesService($http, ConfigService, HttpRequestService) {
    'ngInject';
    const service = {};

    service.getClientAppFeatures = (platform, application, version, agency) => {
        return HttpRequestService.get(ConfigService.api.GET_CLIENT_APP_FEATURES, {
            params: {
                platform: platform,
                version: version,
                application: application,
                agency: agency
            },
            skipAuthorization: true
        });
    };
    return service;
}

export default {
    name: 'ClientAppFeaturesService',
    fn: ClientAppFeaturesService
};