'use strict';

function LoginPartialCtrl(AgencyInfoService, $location, ConfigService) {
    "ngInject";

    const vm = this;
    const agency = $location.search().a;
    const AssetUrl = ConfigService.getAssetUrl();

    vm.images = [];
    vm.images.left_main_logo = `${AssetUrl}${AgencyInfoService.login_reg.left_main_logo}`;
    vm.images.left_main_logo_text = AgencyInfoService.login_reg.left_main_logo_text;

    if (agency === "on_london") {
        vm.message = {
            title: "",
            message: "..........Wherever Life Takes You"
        };
    } else {
        vm.message = {
            title: "",
            message: "enjoy the journey"
        };
    }

    vm.footer = {};
    vm.footer.dynamicText = `\u00A9 ${new Date().getFullYear()}`;
}

export default {
    name: 'LoginPartialCtrl',
    fn: LoginPartialCtrl
};
