'use strict';

function PhoneInputFilter() {
    "ngInject";

    return function(tel) {

        if (!tel) {
            return '';
        }

        switch (tel.length) {
        case 1:
            if (tel.match(/^\($/)) {
                return tel;
            } else {
                return "(" + tel;
            }
        case 5:
            if (tel.match(/^\([0-9]{3}\)$/)) {
                return tel;
            } else {
                return tel.slice(0, 4) + ") " + tel.slice(4, 5);
            }
        case 6:
            if (tel.match(/^\([0-9]{3}\) $/)) {
                return tel;
            } else {
                return tel.slice(0, 5) + " " + tel.slice(5, 6);
            }
        case 10:
            if (tel.match(/^\([0-9]{3}\) [0-9]{3}-$/)) {
                return tel;
            } else {
                return tel.slice(0, 9) + "-" + tel.slice(9, 10);
            }
        case 15:
            return tel.slice(0, 14);
        }
    };
}

export default {
    name: 'phoneInput',
    fn: PhoneInputFilter
};
