'use strict';

function CustomTermsDirective() {
    "ngInject";


    const getTemplate = () => {
        return '<div layout="row layout-margin">'+
      '<div ng-repeat="info in termsAndConditions.termsInfo.info">'+
      '<div ng-bind-html="info.text">'+
        '{{info.text}}'+
      '</div>'+
    '</div>'+
  '</div>';
    };

    return {
        restrict: 'E',
        template: getTemplate(),
        controller: 'TermsConditionsCtrl as termsAndConditions'
    };
}

export default {
    name: 'customTerms',
    fn: CustomTermsDirective
};
