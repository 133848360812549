'use strict';
/**
 * Design by Contract 
 *
 * @module DBC
 */
const error = require('rmpay-errors');

/**
 * Design by Contract and JS type checker 
 *
 * @class DBC
 */

const precondition_error_code = "dbc_precondition";
const postcondition_error_code = "dbc_postcondition";
const invariant_error_code = "dbc_invariantcondition";
const assertion_error_code = "dbc_assertcondition";

module.exports = {
    /**
 	* Used to determine if a precondition is valid
 	*
 	* @method require
 	* @param {boolean} condition
 	* @return null
 	*/
    require: function(condition) {
        if (!condition) {
            throw error(precondition_error_code);
        }
    },
    /**
 	* Used to determine if a precondition is valid and adds a user-defined message to thrown error
 	*
 	* @method requireWithMessage
 	* @param {boolean} condition
 	* @param {string} message
 	* @return null
 	*/
    requireWithMessage: function(condition, message) {
        if (!condition) {
            throw error(precondition_error_code, message);
        }
    },
    /**
 	* Used to determine if a postcondition is valid
 	*
 	* @method ensure
 	* @param {boolean} condition
 	* @return null
 	*/
    ensure: function(condition) {
        if (!condition) {
            throw error(postcondition_error_code);
        }
    },
    /**
 	* Used to determine if a postcondition is valid and adds a user-defined message to thrown error
 	*
 	* @method ensureWithMessage
 	* @param {boolean} condition
 	* @param {string} message
 	* @return null
 	*/
    ensureWithMessage: function(condition, message) {
        if (!condition) {
            throw error(postcondition_error_code, message);
        }
    },
    /**
 	* Used to determine if an assertion is valid
 	*
 	* @method assert
 	* @param {boolean} condition
 	* @return null
 	*/
    assert: function(condition) {
        if (!condition) {
            throw error(assertion_error_code);
        }
    },
    /**
 	* Used to determine if an assertion is valid and adds a user-defined message to thrown error
 	*
 	* @method assertWithMessage
 	* @param {boolean} condition
 	* @param {string} message
 	* @return null
 	*/
    assertWithMessage: function(condition, message) {
        if (!condition) {
            throw error(assertion_error_code, message);
        }
    },
    /**
 	* Used to determine if an invariant is valid
 	*
 	* @method invariant
 	* @param {boolean} condition
 	* @return null
 	*/
    invariant: function(condition) {
        if (!condition) {
            throw error(invariant_error_code);
        }
    },
    /**
 	* Used to determine if an invariant is valid and adds a user-defined message to thrown error
 	*
 	* @method invariantWithMessage
 	* @param {boolean} condition
 	* @param {string} message
 	* @return null
 	*/
    invariantWithMessage: function(condition, message) {
        if (!condition) {
            throw error(invariant_error_code, message);
        }
    },
    /**
     * Used to determine if object has property
     *
     * @method hasMember
     * @param {object} obj
     * @param {string} memberName
     * @return null
     */
    hasMember: function(obj, memberName) {
        if (!obj.hasOwnProperty(memberName)) {
            throw error(assertion_error_code, "Object is missing member: " + memberName);
        } else {
            return true;
        }
    },
    /**
     * Used to determine if object has property in list
     *
     * @method hasMemberIn
     * @param {object} obj
     * @param {string} memberName
     * @param {array} memberValues
     * @return null
     */
    hasMemberIn: function(obj, memberName, memberValues) {
        if (!obj.hasOwnProperty(memberName)) {
            throw error(assertion_error_code, 'Object is missing member: ' + memberName);
        } else if (-1 === memberValues.indexOf(obj[memberName])) {
            throw error(assertion_error_code, 'Object member (' + memberName + ') should be one of the following: ' + memberValues);
        } else {
            return true;
        }
    },
    /**
 	* Used to determine if a variable is a string
 	*
 	* @method isString
 	* @param variable
 	* @return {boolean} result
 	*/
    isString: function(variable) {
        //console.log("Variable type is: " + typeof variable);
        return typeof variable === "string";
    },
    /**
 	* Used to determine if a variable is a number
 	*
 	* @method isNumber
 	* @param variable
 	* @return {boolean} result
 	*/
    isNumber: function(variable) {
        // console.log(variable);
        return typeof variable === "number" && !isNaN(variable);
    },
    /**
	 * Used to determine if a number is an integer
	 *
	 * @method isInteger
	 * @param  variable
	 * @return {boolean} result
	 */
    isInteger: function(variable) {
        // console.log(variable);
        return (module.exports.isNumber(variable) && (variable % 1 === 0));
    },
    /**
 	* Used to determine if a variable is a boolean
 	*
 	* @method isBoolean
 	* @param variable
 	* @return {boolean} result
 	*/
    isBoolean: function(variable) {
        //console.log("Variable type is: " + typeof variable);
        return typeof variable === "boolean";
    },
    /**
 	* Used to determine if a variable is an object
 	*
 	* @method isObject
 	* @param variable
 	* @return {boolean} result
 	*/
    isObject: function(variable) {
        //console.log("Variable type is: " + typeof variable);
        return (typeof variable === "object" && !Array.isArray(variable) && null !== variable);
    },
    /**
 	* Used to determine if a variable is an array
 	*
 	* @method isArray
 	* @param variable
 	* @return {boolean} result
 	*/
    isArray: function(variable) {
        //console.log("Variable type is: " + typeof variable);
        return Array.isArray(variable);
    },
    /**
 	* Used to determine if a variable is undefined
 	*
 	* @method isUndefined
 	* @param variable
 	* @return {boolean} result
 	*/
    isUndefined: function(variable) {
        //console.log("Variable type is: " + typeof variable);
        return typeof variable === "undefined";
    },
    /**
 	* Used to determine if a variable is a null
 	*
 	* @method isNull
 	* @param variable
 	* @return {boolean} result
 	*/
    isNull: function(variable) {
        //console.log("Variable type is: " + typeof variable);
        return variable === null;
    },
    /**
 	* Used to determine if a variable is a function
 	*
 	* @method isFunction
 	* @param variable
 	* @return {boolean} result
 	*/
    isFunction: function(variable) {
        //console.log("Variable type is: " + typeof variable);
        return typeof variable === "function";
    }
};

