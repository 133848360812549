'use strict';

function CustomerService(ConfigService, JWTService, HttpRequestService) {
    'ngInject';

    const service = {};

    service.getCustomer = (rmid) => {
        return HttpRequestService.get(ConfigService.api.GET_CUSTOMER + rmid);
    };

    return service;
}

export default {
    name: 'CustomerService',
    fn: CustomerService
};
