'use strict';

const parse = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
};

function JWTService() {
    'ngInject';

    const service = {};
    const tokenName = "token";

    let token;

    service.getEmail = () => {
        token = localStorage.getItem(tokenName);
        const data = parse(token);
        return data.email;
    };

    service.getRmid = () => {
        token = localStorage.getItem(tokenName);
        const data = parse(token);
        return data.rmid;
    };

    service.getDatabase = () => {
        token = localStorage.getItem(tokenName);
        const data = parse(token);
        return data.database;
    };

    service.getUserId = () => {
        token = localStorage.getItem(tokenName);
        const data = parse(token);
        return data.user_id;
    };

    service.setToken = (token) => {
        localStorage.setItem(tokenName, token);
    };

    service.getToken = () => {
        token = localStorage.getItem(tokenName);
        return token;
    };

    service.getUserRole = () => {
        token = localStorage.getItem(tokenName);
        const data = parse(token);
        return data.role;
    };

    service.getUserType = () => {
        token = localStorage.getItem(tokenName);
        const data = parse(token);
        return data.type;
    };

    service.removeToken = () => {
        token = localStorage.removeItem(tokenName);
    };

    service.isExpired = () => {
        token = localStorage.getItem(tokenName);
        const expiryDate = parse(token).exp;
        if (expiryDate * 1000 < new Date().getTime()) {
            return true;
        } else {
            return false;
        }
    };
  
    service.isValidated = () => {
        token = localStorage.getItem(tokenName);
        const emailValidated = parse(token).email_validated;
        return emailValidated;
    };

    service.getAgencyId = () => {
        const token = localStorage.getItem(tokenName);
        const data = parse(token);
        if (data.agencyId) {
            return parseInt(data.agencyId);
        } else {
            return null;
        }
    };

  
    return service;
}

export default {
    name: 'JWTService',
    fn: JWTService
};
