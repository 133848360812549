'use strict';

function NextTripService($http, AgencyInfoService, JWTService, TripsService, DialogService,
    $state, $q, moment, _) {
    'ngInject';

    const service = {};

    const vm = this;

    const getBookingTripRules = () => {
        let dateRange;
        return AgencyInfoService.bookTripRules().then((data) => {
            const bookTripRules = data[0].rules;
            for (let i = 0; i < bookTripRules.length; i++) {
                const index = bookTripRules[i].rule.rule;
                switch (bookTripRules[i].rule.meta.identity) {
                case "BOOKABLE-DATE-RANGE":
                    dateRange = index.dateRange;
                    vm.dateRange = dateRange;
                    break;
                case "SCHEDULED-TRIPS-THRESHOLD-MINUTES":
                    vm.schedTripsThresholdMinutes = index.schedTripsThresholdMinutes;
                    break;
                }
            }
            return dateRange;
        });
    };

    const getNextJourney = (dateRange) => {
        const userid = JWTService.getUserId();
        const rmid = JWTService.getRmid();
        const currentDate = moment().format('YYYY-MM-DD');
        const endDate = moment(currentDate).add(dateRange, 'days').format('YYYY-MM-DD');
        return TripsService.getJourneys(userid, currentDate, endDate, rmid, false)
            .then(result => {
                let journeys;
                if (result.length > 0) {
                    let thresholdMinutes = vm.schedTripsThresholdMinutes;
                    if (isNaN(thresholdMinutes)) {
                        thresholdMinutes = 0;
                    }
                    const currTime = moment();
                    const thresholdTime = currTime.add(moment.duration(-thresholdMinutes, 'minutes'));
                    journeys = result.filter(function (journey) {
                        switch (journey.status) {
                        case 'Completed':
                        case 'Cancelled':
                            return false;
                        case 'Scheduled':
                            return (moment(journey.tripTime, 'YYYY-MM-DDThh:mm:ssZ') >= thresholdTime);
                        default: // Unscheduled, Pending Approval
                            return (moment(journey.tripTime, 'YYYY-MM-DDThh:mm:ssZ') >= currTime);
                        }
                    });
                    if (journeys.length === 0) {
                        return journeys;
                    } else {
                        journeys = journeys.reduce(function (prev, curr) {
                            return prev.tripTime < curr.tripTime ? prev : curr;
                        });
                    }
                    return journeys;
                } else {
                    return null;
                }
            })
            .then((trip) => {
                if (!_.isNull(trip)) {
                    return trip;
                }
            }).catch(() => {
                return null;
            });
    };

    service.getNextTrip = () => {
        return getBookingTripRules()
            .then((dateRange) => {
                return dateRange;
            }).then(dateRange => {
                return getNextJourney(dateRange);
            });
    };

    return service;
}

export default {
    name: 'NextTripService',
    fn: NextTripService
};
