'use strict';

function ConfigService(APP_CONFIG, $location) {
    'ngInject';

    const service = { api: {} };

    let Base_url;
    let Asset_url;
    let App_url = $location.protocol() + '://' + $location.host();

    const configApi = () => {
        service.api.LOGIN =                       Base_url + APP_CONFIG.services.auth_svc + APP_CONFIG.api.login;
        service.api.GET_BRANDING =                Base_url + APP_CONFIG.services.agency_svc + APP_CONFIG.api.get_branding;
        service.api.SIGNUP =                      Base_url + APP_CONFIG.services.auth_svc + APP_CONFIG.api.signup;
        service.api.GET_MOBILITY_TYPES =          Base_url + APP_CONFIG.services.rmxs_svc + APP_CONFIG.api.mobility_types;
        service.api.GET_TRIP_PURPOSES =           Base_url + APP_CONFIG.services.rmxs_svc + APP_CONFIG.api.trip_purposes;
        service.api.GET_RMXS_ADDR =               Base_url + APP_CONFIG.services.rmxs_svc + APP_CONFIG.api.rmxs_addr;
        service.api.GET_RMXS =                    Base_url + APP_CONFIG.services.rmxs_svc;
        service.api.FACILITY_LOGIN =              App_url  + APP_CONFIG.facility_login.port + APP_CONFIG.facility_login.proxy;
        service.api.GET_ITINERARY =               Base_url + APP_CONFIG.services.tripplanning_svc + APP_CONFIG.api.get_itinerary;
        service.api.GET_RIDES =                   Base_url + APP_CONFIG.api.get_rides;
        service.api.GET_CUSTOMER =                Base_url + APP_CONFIG.services.rmxs_svc + APP_CONFIG.api.get_customer;
        service.api.BOOK_TRIP =                   Base_url + APP_CONFIG.services.tripbooking_svc + APP_CONFIG.api.book_trip;
        service.api.BOOKING_PERMITTED =           Base_url + APP_CONFIG.services.tripbooking_svc + APP_CONFIG.api.booking_permitted;
        service.api.GET_RIDER =                   Base_url + APP_CONFIG.services.auth_svc + APP_CONFIG.api.get_rider;
        service.api.GET_TRIPS =                   Base_url + APP_CONFIG.services.tripview_svc + APP_CONFIG.api.get_trips;
        service.api.GET_JOURNEYS =                Base_url + APP_CONFIG.services.tripview_svc + APP_CONFIG.api.get_journeys;
        service.api.GET_SPECIFIC_JOURNEY =        Base_url + APP_CONFIG.services.tripview_svc + APP_CONFIG.api.get_specific_journey;
        service.api.CANCEL_TRIP =                 Base_url + APP_CONFIG.services.tripbooking_svc             + APP_CONFIG.api.cancel_trip;
        service.api.CHANGE_PASSWORD =             Base_url + APP_CONFIG.services.auth_svc + APP_CONFIG.api.change_password;
        service.api.GET_RULES =                   Base_url + APP_CONFIG.services.business_rules + APP_CONFIG.api.get_rules;
        service.api.GET_RESOURCES =               Base_url + APP_CONFIG.services.agency_resources + APP_CONFIG.api.get_resources;
        service.api.REQUEST_PASSWORD_EMAIL =      Base_url + APP_CONFIG.services.auth_svc + APP_CONFIG.api.request_password_email;
        service.api.REQUEST_CONFIRMATION_EMAIL =  Base_url + APP_CONFIG.services.validation_svc + APP_CONFIG.api.request_confirmation_email;
        service.api.GET_VEHICLE_VIEW =            Base_url + APP_CONFIG.services.vehicleview_svc + APP_CONFIG.api.get_location;
        service.api.GET_CLIENT_APP_FEATURES =     Base_url + APP_CONFIG.services.app_features_svc + APP_CONFIG.api.get_client_features;
        service.api.GET_TIMEZONE =                Base_url + APP_CONFIG.services.agency_svc + APP_CONFIG.api.get_time_zone;
    };

    service.setBaseUrl = (urls) => {
        const ENV = APP_CONFIG.ENV;
        service.Base_url = Base_url = ENV === 'dev'? APP_CONFIG.url : urls[ENV];
        console.log('found base url', Base_url);
        localStorage.setItem('Base_url', Base_url);
        configApi();
    };

    service.getBaseUrl = () => {
        service.Base_url = Base_url = localStorage.getItem('Base_url');
        if (Base_url) { configApi(); }
        return Base_url;
    };

    service.setAssetUrl = urls => {
        const ENV = APP_CONFIG.ENV;
        service.Asset_url = Asset_url = (ENV === 'dev') ? APP_CONFIG.Asset_url : urls[ENV];
        localStorage.setItem('Asset_url', Asset_url);
        service.api.GET_BRANDING =  `${Asset_url}/assets/images`;
    };

    service.getAssetUrl = () => {
        service.Asset_url = Asset_url = localStorage.getItem('Asset_url');
        if (Asset_url) {
            service.api.GET_BRANDING =  `${Asset_url}/assets/images`;
        }
        return Asset_url;
    };

    return service;
}

export default {
    name: 'ConfigService',
    fn: ConfigService
};
