'use strict';

function MyTripsCtrl($scope, $window, JWTService, _, moment, TripsService, $state, $stateParams, AgencyInfoService, $locale) {
    'ngInject';

    const vm = this;
    vm.errorMessage = "";

    let currentDate = moment().format('YYYY-MM-DD');

    vm.pageParams = {
        errorMessage: "",
        currentActive: 0,
        rideList: [],
        startIndex: 0,
        sizeOfRidesList: 0
    };

    //will be altered later to have a date that is selectable, for now 2 weeks
    const removeTime = (time) => {
        return moment(time).subtract(vm.dateRange, 'days');
    };

    const addTime = (time) => {
        return moment(time).add(vm.dateRange, 'days');
    };

    const getJourneys = (startDateToPass, endDateToPass) => {
        vm.pageParams.rideList = [];
        vm.pageParams.rideList = 0;
        const billingInformation = false;
        const userid = JWTService.getUserId();
        const rmid = JWTService.getRmid();
        const startDate = moment(startDateToPass).format('YYYY-MM-DD');
        const endDate = moment(endDateToPass).format('YYYY-MM-DD');
        TripsService.getJourneys(userid, startDate, endDate, rmid, billingInformation)
            .then(function (response) {
                const futureTripList = [];
                const pastTripList = [];

                let thresholdMinutes = vm.schedTripsThresholdMinutes;
                if (isNaN(thresholdMinutes)) {
                    thresholdMinutes = 0;
                }
                const thresholdTime = moment().add(moment.duration(-thresholdMinutes, 'minutes'));

                response.forEach(trip => {
                    trip.showPriceVerbiage = Number.isNaN(Number.parseFloat(trip['expCustomerPay']));

                    switch (trip.status) {
                        case 'Completed':
                            pastTripList.push(trip);
                            break;
                        case 'Scheduled':
                            if (moment(trip.tripTime, 'YYYY-MM-DDThh:mm:ssZ') >= thresholdTime) {
                                futureTripList.push(trip);
                            } else {
                                pastTripList.push(trip);
                            }
                            break;
                        default: // Unscheduled, Cancelled, Pending Approval
                            if (moment(trip.tripTime, 'YYYY-MM-DDThh:mm:ssZ') >= moment()) {
                                futureTripList.push(trip);
                            } else {
                                pastTripList.push(trip);
                            }
                            break;
                    }
                });
                vm.pageParams.futureRideList = futureTripList.sort((tripA, tripB) => { return moment(tripA.tripTime) - moment(tripB.tripTime); });
                vm.pageParams.pastRideList = pastTripList.sort((tripA, tripB) => { return moment(tripA.tripTime) - moment(tripB.tripTime); }).reverse();
            })
            .catch(function (err) {
                vm.errorMessage = err;
            });
        return null;
    };

    const getFutureTrips = () => {
        currentDate = moment();
        const startDate = currentDate;
        const endDate = addTime(currentDate);
        getJourneys(startDate, endDate, true);
    };

    vm.previousTripsView = () => {
        if (!vm.viewTripDisable) {
            currentDate = moment();
            const startDate = removeTime(currentDate);
            const endDate = currentDate;
            getJourneys(startDate, endDate, true);
        }
    };

    vm.futureTripsView = () => {
        if (!vm.viewTripDisable) {
            getFutureTrips();
        }
    };


    vm.viewJourneyBreakdown = (journey) => {
        currentDate = moment().format('YYYY-MM-DD');
        $state.go('Rider.viewTrip', {
            journeyid: journey.id
        });
    };

    vm.format = (date) => {
        return date.toLocaleDateString($locale.id);
    };

    const getBookingTripRules = () => {
        AgencyInfoService.bookTripRules().then((data) => {
            const bookTripRules = data[0].rules;
            for (let i = 0; i < bookTripRules.length; i++) {
                const index = bookTripRules[i].rule.rule;
                switch (bookTripRules[i].rule.meta.identity) {
                    case "VIEW-TRIP-DISABLE":
                        vm.viewTripDisable = index.viewTripDisable;
                        break;
                    case "BOOKABLE-DATE-RANGE":
                        vm.dateRange = index.dateRange;
                        break;
                    case "SCHEDULED-TRIPS-THRESHOLD-MINUTES":
                        vm.schedTripsThresholdMinutes = index.schedTripsThresholdMinutes;
                        break;
                }
            }
        }).then(() => {
            getFutureTrips();
        });
    };
    getBookingTripRules();
}

export default {
    name: 'MyTripsCtrl',
    fn: MyTripsCtrl
};
