'use strict';

function SearchCtrl() {
    'ngInject';
}

export default {
    name: 'SearchCtrl',
    fn: SearchCtrl
};
