'use strict';

function LoginService($http, ConfigService, HttpRequestService) {
    'ngInject';

    const service = {};

    service.signup = (user) => {
        return HttpRequestService.post(ConfigService.api.SIGNUP, user, {
            skipAuthorization: true
        });
    };

    service.login = (user) => {
        return HttpRequestService.post(ConfigService.api.LOGIN, user, {
            skipAuthorization: true
        });
    };

    return service;

}

export default {
    name: 'LoginService',
    fn: LoginService
};
