module.exports={
  "unknown": {
    "status": 500,
    "message": "Unknown internal error",
    "description": "There's an error with our systems and we don't know the exact reason. Please contact us if it continues."
  },
  "not_implemented": {
    "status": 501,
    "message": "Functionality not implemented",
    "description": "The server does not support the functionality required to fulfill the request"
  },
  "forbidden": {
    "status": 403,
    "message": "Forbidden",
    "description": "The user is forbidden from accessing this resource"
  },
  "azure_error": {
    "status": 500,
    "message": "Azure Service Error",
    "description": "A request to Azure API could not be completed"
  },
  "jwt_missing": {
    "status": 400,
    "message": "Missing JWT",
    "description": "A JsonWebToken was not detected when expected"
  },
  "jwt_verification": {
    "status": 401,
    "message": "Invalid JWT",
    "description": "Json Web Token failed to verify"
  },
  "jwt_missing_prop": {
    "status": 400,
    "message": "Invalid Property",
    "description": "The requested property is not contained in the token"
  },
  "invalid_param": {
    "status": 400,
    "message": "Invalid argument(s)",
    "description": "At least one parameter is either invalid, or missing-and-required"
  },
  "logic_error": {
    "status": 400,
    "message": "Invalid operation(s)",
    "description": "Operation not permitted by application logic engine"
  },
  "no_smartcard": {
    "status": 400,
    "message": "No such smartcard",
    "description": "Attempted to access a nonexistent smartcard"
  },
  "missing_param": {
    "status": 400,
    "message": "A required parameter is missing from the request",
    "description": "There is a required parameter missing from the request, please add it and try again."
  },
  "account_lookup": {
    "status": 400,
    "message": "User account could not be retrieved",
    "description": "User account could not be retrieved, possible data error"
  },
  "unique_account_lookup": {
    "status": 400,
    "message": "Extra data required to uniquely identify account",
    "description": "User account could not be retrieved using provided information.  Please add RouteMatch ID (can be obtained by contacting Customer Servica)e"
  },
  "timeout_connect": {
    "status": 408,
    "message": "Establishing a connection failed due to timeout",
    "description": "Connection could not be established, and attempt to connect timed out"
  },
  "timeout_read": {
    "status": 408,
    "message": "Timeout while reading on an open connection",
    "description": "Connection established, but timeout occurred while awaiting resposne"
  },
  "db_error": {
    "status": 400,
    "message": "Database error",
    "description": "Error occurred when interacting with database"
  },
  "db_save_error": {
    "status": 400,
    "message": "Database save error",
    "description": "Error in saving data to database"
  },
  "json_schema": {
    "status": 400,
    "message": "Invalid input",
    "description": "Input does not validate against a json schema"
  },
  "invalid_data": {
    "status": 400,
    "message": "Invalid Data",
    "description": "Data structure incorrect"
  },
  "invalid_json": {
    "status": 400,
    "message": "Invalid JSON Data",
    "description": "JSON data could not be parsed"
  },
  "invalid_yaml": {
    "status": 400,
    "message": "Invalid YAML Data",
    "description": "YAML data could not be parsed"
  },
  "unknown_operator": {
    "status": 400,
    "message": "Unknown operator",
    "description": "The supplied operator is not supported"
  },
  "not_found": {
    "status": 404,
    "message": "Resource could not be located",
    "description": "Resource could not be located, nothing at specified location"
  },
  "duplicate_entry": {
    "status": 409,
    "message": "Request could not be completed due to a conflict with the current state of the resource",
    "description": "The resource already exists in the database"
  },
  "bluefin_error": {
    "status": 500,
    "message": "Request to BlueFin could not be completed",
    "description": "An error occured while processing the request"
  },
  "invalid_csv": {
    "status": 400,
    "message": "Invalid CSV Data",
    "description": "CSV data could not be parsed"
  },
  "invalid_gtfs_feed": {
    "status": 400,
    "message": "Invalid GTFS Feed",
    "description": "The zip uploaded contains invalid file(s)"
  },
  "deleting_file_error": {
    "status": 500,
    "message": "Error deleting a file or directory",
    "description": "Could not delete the file of folder specified"
  },
  "unzipping_error": {
    "status": 500,
    "message": "Error Unzipping file",
    "description": "Error encountered while unzipping the uploaded file"
  },
  "invalid_file_format": {
    "status": 400,
    "message": "Invalid file format",
    "description": "The uploaded file is not the correct format"
  },
  "read_file_error": {
    "status": 500,
    "message": "Unable read a file",
    "description": "A file required was unable to be read"
  },
  "write_stream_error": {
    "status": 500,
    "message": "Error occurred while writing to a file",
    "description": "A file being written to encountered an error"
  },
  "directory_not_found": {
    "status": 500,
    "message": "Error occurred while retrieving a directory",
    "description": "A directory being retrieved does not exist"
  },
  "test_no_status": {
    "message": "Test Error",
    "description": "A test error which has no status defined"
  },
  "undetermined_file_format": {
    "status": 400,
    "message": "Undetermined result format",
    "description": "The file is not the correct format"
  },
  "invalid_XML": {
    "status": 400,
    "message": "invalid XML",
    "description": "The file is not valid XML"
  },
  "fail_csv_to_json": {
    "status": 500,
    "message": "Failed to process the recieved response",
    "description": "Failed to convert CSV to JSON"
  },
  "cannot_resolve": {
    "status": 500,
    "message": "Records cannot resolve trip/customer ID",
    "description": "CSV empty response"
  },
  "empty_query_result": {
    "status": 404,
    "message": "Empty Query Result",
    "description": "The query result was empty"
  },
  "multiple_results": {
    "status": 422,
    "message": "Multiple Query Result",
    "description": "Multiple results have been returned from query"
  },
  "customer_id_notFound": {
    "status": 500,
    "message": "CustomerID not found",
    "description": "CustomerID was not found in parsed csv object"
  },
  "response_error": {
    "status": 500,
    "message": "Response INVALID_PASSWORD",
    "description": "Phone number not found in RouteMatch records"
  },
  "no_command_response": {
    "status": 422,
    "message": "No LOOKUP_CUSTOMER command response",
    "description": "(ambiguous query?)No LOOKUP_CUSTOMER command response"
  },
  "incorrect_date_format": {
    "status": 400,
    "message": "Incorrect Date Format",
    "description": "Incorrect Date Foemat Supplied"
  },
  "agency_not_exist": {
    "status": 404,
    "message": "Agency does not exist",
    "description": "Agency does not exist"
  },
  "query_failure": {
    "status": 404,
    "message": "accessQueryResultValue failure",
    "description": "Error processing query"
  },
  "phone_not_found": {
    "status": 404,
    "message": "Phone number not found in RouteMatch records",
    "description": "The Phone number not found in RouteMatch records"
  },
  "csv_query_error": {
    "status": 500,
    "message": "A property existed when it should not have",
    "description": "There was an error parsing CSV from RM database, it contained unexpected properties"
  },
  "empty_rmxs_result": {
    "status": 500,
    "message": "No result received from RMXS service",
    "description": "There was an empty result received from the RMXS service"
  },
  "json_to_xml_parsing_error": {
    "status": 500,
    "message": "Error while trying to convert json to xml",
    "description": "An error occurred while trying to convert an array to xml"
  },
  "remap_properties_error": {
    "status": 500,
    "message": "Error while remapping properties in object",
    "description": "An error occurred while trying to remap an object's properties"
  },
  "trip_booking_error": {
    "status": 500,
    "message": "Error while booking trips",
    "description": "An error has occurred while trying to book trips"
  },
  "login_failure": {
    "status": 401,
    "message": "Failed to login",
    "description": "The user failed to login"
  },
  "multi_insert_error": {
    "status": 500,
    "message": "Error trying to build a multi insert string",
    "description": "There was error trying to build a multi insert string,most likely it was because the keys of an objects"
  },
  "trip_cancellation_error": {
    "status": 500,
    "message": "Error while booking trips",
    "description": "An error has occurred while trying to book trips"
  },
  "invalid_validation_token": {
    "status": 401,
    "message": "Error while validating token",
    "description": "The token is expired or has been entered incorrectly"
  },
  "invalid_transfer": {
    "status": 400,
    "message": "Error while transferring tickets",
    "description": "The transferer and transferee user identifiers cannot be the same"
  },
  "refresh_token_invalid": {
    "status": 400,
    "message": "Refresh Token invalid",
    "description": "Validation of client refresh token failed, dependent workflow(s) could not proceed"
  },
  "psp_not_exist": {
    "status": 404,
    "message": "PSP does not exist",
    "description": "PSP does not exist"
  },
  "psp_action_not_exist": {
    "status": 404,
    "message": "PSP Action does not exist",
    "description": "PSP Action does not exist"
  },
  "psp_authorization_not_exist": {
    "status": 404,
    "message": "PSP Authorization does not exist",
    "description": "No authorization mechanism has been defined for this PSP"
  },
  "psp_transform_not_exist": {
    "status": 404,
    "message": "PSP Transform Receipt does not exist",
    "description": "No transform receipt mechanism has been defined for this PSP"
  },
  "psp_get_transaction_id_not_exist": {
    "status": 404,
    "message": "PSP GET Transaction ID does not exist",
    "description": "No get transaction id mechanism has been defined for this PSP"
  },
  "psp_transaction_not_exist": {
    "status": 404,
    "message": "PSP Transaction does not exist",
    "description": "No transaction mechanism has been defined for this PSP"
  },
  "psp_get_transaction_status_not_exist": {
    "status": 404,
    "message": "PSP Get Transaction Status does not exist",
    "description": "No get transaction status mechanism has been defined for this PSP"
  },
  "psp_get_transaction_result_not_exist": {
    "status": 404,
    "message": "PSP Get Transaction Result does not exist",
    "description": "No get transaction result mechanism has been defined for this PSP"
  },
  "invalid_payment_medium": {
    "status": 400,
    "message": "Invalid Payment Medium",
    "description": "An invalid payment medium was provided for the purchase order"
  },
  "insufficient_balance": {
    "status": 400,
    "message": "Insufficient Balance",
    "description": "The users balance is insufficient to carry out the requested transaction"
  },
  "already_processed": {
    "status": 400,
    "message": "Transaction Already Processed",
    "description": "This transaction has already been processed"
  },
  "propay_error": {
    "status": 500,
    "message": "Propay error",
    "description": "Something went wrong while contacting propay"
  },
  "dbc_precondition": {
    "status": 500,
    "message": "Platform error",
    "description": "Precondition violation"
  },
  "dbc_postcondition": {
    "status": 500,
    "message": "Platform error",
    "description": "Postcondition violation"
  },
  "dbc_invariantcondition": {
    "status": 500,
    "message": "Platform error",
    "description": "Invariant violation"
  },
  "dbc_assertcondition": {
    "status": 500,
    "message": "Platform error",
    "description": "Assertion violation"
  },
  "payment_card_failure_client": {
    "status": 400,
    "message": "Card Transaction Failure",
    "description": "Something went wrong with the card transaction"
  },
  "authentication_error": {
    "status": 401,
    "message": "Authentication Failure",
    "description": "Something went wrong while trying to authenticate with the platform"
  },
  "missing_business_rule": {
    "status": 500,
    "message": "Missing Business Rule",
    "description": "A required business rule has not been configured"
  },
  "max_saved_credit_cards": {
    "status": 400,
    "message": "Maximum saved credit card limit reached",
    "description": "The maximum saved credit card limit has been reached"
  },
  "internal_authorisation_error": {
    "status": 500,
    "message": "Authorisation error",
    "description": "An error occurred while interacting with our authorisation system"
  },
  "max_active_jobs": {
    "status": 500,
    "message": "Max active jobs reached",
    "description": "The number of active jobs has been reached for this schedule type"
  }
}
