'use strict';

function RiderCtrl($state, JWTService, menu, $window, Logout, AgencyInfoService, CustomerService, ConfigService) {
    "ngInject";

    const vm = this;
    const AssetUrl = ConfigService.getAssetUrl();

    vm.username = JWTService.getEmail();
    vm.menu = menu;
    vm.isShow = false;
    vm.autoFocusContent = false;
    vm.isNavMenuCollapse = false;
    vm.images = [];
    vm.images.main_logo = `${AssetUrl}${AgencyInfoService.header.main_logo}`;

    vm.home = () => {
        $state.go('Rider.home');
    };
  

    vm.isOpen = (section) => {
        if (vm.isShow === true) {
            return menu.isSectionSelected(section);
        } else {
            return false;
        }
    };
  
    vm.toggleNavMenu = () =>{
        vm.isNavMenuCollapse = !vm.isNavMenuCollapse;
    };
    vm.toggleOpen = (section) => {
        menu.toggleSelectSection(section);
    };

    vm.isShowNav = () => {
        return vm.isShow;
    };

    vm.showNav = (bool) => {
        vm.isShow = bool;
    };

    vm.showNavByHam = () => {
        if (vm.isShow === true)
        {
            vm.showNav(false);
        } else {
            vm.showNav(true);
        }

    };

    vm.logout = () => {
        Logout.logout('signout');
    };

    vm.uriClick = (uri) => {
        $window.location.href = uri;
    };

    const getCustomerDetail = () => {
        const rmidToPass = JWTService.getRmid();
        CustomerService.getCustomer(rmidToPass).then((res) => {
            vm.fullName = res.forename + " " + res.surname;
        });

    };

    getCustomerDetail();

}


export default {
    name: 'RiderCtrl',
    fn: RiderCtrl
};
