'use strict';
import * as $_ from 'lodash';

function _() {
    'ngInject';

    return $_;
}

export default {
    name: '_',
    fn: _
};