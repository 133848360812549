"use strict";

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

// Customized browserify bundle preamble that properly passes arbitary(ish) require() calls through correctly.
// Based off https://github.com/substack/browser-pack/blob/master/prelude.js
// TODO: Maybe submit this back to the `browser-pack` project?


// modules are defined as an array
// [ module function, map of requireuires ]
//
// map of requireuires is short require name -> numeric require
//
// anything defined in a previous bundle is accessed via the
// orig method which is the requireuire for previous bundles

(function outer(modules, cache, entry) {
    // Save the require from previous bundle to this closure if any
    var previousRequire = typeof require == "function" && require;

    function newRequire(name) {
        for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
            args[_key - 1] = arguments[_key];
        }

        // `jumped` is always the last (optional) argument
        var jumped = typeof args[args.length - 1] === 'boolean' ? args.pop() : false;
        if (!cache[name]) {
            if (!modules[name]) {
                // if we cannot find the module within our internal map or
                // cache jump to the current global require ie. the last bundle
                // that was added to the page.
                var currentRequire = typeof require == "function" && require;
                if (!jumped && currentRequire) {
                    return currentRequire.apply(undefined, [name].concat(_toConsumableArray(args), [true]));
                }

                // If there are other bundles on this page the require from the
                // previous one is saved to 'previousRequire'. Repeat this as
                // many times as there are bundles until the module is found or
                // we exhaust the require chain.
                if (previousRequire) return previousRequire.apply(undefined, [name].concat(_toConsumableArray(args), [true]));
                var err = new Error('Cannot find module \'' + name + '\'');
                err.code = 'MODULE_NOT_FOUND';
                throw err;
            }
            var module = cache[name] = { exports: {} };
            args = arguments;
            modules[name][0].call(module.exports, function (x) {
                var id = modules[name][1][x];

                for (var _len2 = arguments.length, etc = Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
                    etc[_key2 - 1] = arguments[_key2];
                }

                return newRequire.apply(undefined, [id ? id : x].concat(etc));
            }, module, module.exports, outer, modules, cache, entry);
        }
        return cache[name].exports;
    }
    for (var i = 0; i < entry.length; i++) {
        newRequire(entry[i]);
    } // Override the current require with this new one
    return newRequire;
})