'use strict';

function TripPlanningService($http, ConfigService, HttpRequestService, $interpolate, _, JWTService, moment) {
    'ngInject';

    const service = {};

    service.getItinerary = (tripInfo) => {
        let queryStr = "";
        const bookTimeDate = moment(tripInfo.dt).format('YYYY-MM-DD');
        const bookTimeTime = moment(tripInfo.bookTime).format('HH:mm:ss');
        const bookTimeZone = moment(tripInfo.dt).format('Z');
        const bookTime = bookTimeDate + "T" + bookTimeTime + bookTimeZone;

        queryStr = `custid=${JWTService.getRmid()}&custmobid=${tripInfo.ridermoid}&serviceid=${tripInfo.serviceId}&origid=${tripInfo.origid}&origaddress=${encodeURIComponent(tripInfo.origaddress)}&origlat=${tripInfo.origlat}&origlong=${tripInfo.origlong}&destid=${tripInfo.destid}&destaddress=${encodeURIComponent(tripInfo.destaddress)}&destlat=${tripInfo.destlat}&destlong=${tripInfo.destlong}&language=en&units=metric&attendcount=${tripInfo.attendantCount}&guestcount=${tripInfo.guestCount}`;

        if (tripInfo.ty === "drop") {
            queryStr += `&dotime=${encodeURIComponent(bookTime)}`;
        } else {
            queryStr += `&putime=${encodeURIComponent(bookTime)}`;
        }

        if (angular.isDefined(tripInfo.attendantmobid)) {
            queryStr += `&attendmobid=${tripInfo.attendantmobid}`;
        }

        if (angular.isDefined(tripInfo.guestmobid)) {
            queryStr += `&guestmobid=${tripInfo.guestmobid}`;
        }

        const getItineraryUrl = ConfigService.api.GET_ITINERARY + "?" + queryStr;

        return HttpRequestService.get(getItineraryUrl);
    };

    return service;
}

export default {
    name: 'TripPlanningService',
    fn: TripPlanningService
};
