'use strict';

function MyAccountCtrl($state, $scope, $window, RmxsService, JWTService, AgencyInfoService) {
    'ngInject';
    const vm = this;

    vm.toBookTrip = () => {
        $state.go('Rider.BookTrip');
    };

    vm.toViewTrips = () => {
        $state.go('Rider.listTrip');
    };

    const getBookingTripRules = () => {
        AgencyInfoService.bookTripRules().then((data)=> {
                const bookTripRules = data[0].rules;
                for (let i = 0; i < bookTripRules.length; i++){
                    const index = bookTripRules[i].rule.rule;
                    switch (bookTripRules[i].rule.meta.identity){
                    case "ALLOW-TRIP-BOOKING":
                        vm.allowTripBooking = index.allowTripBooking;
                        break;
                    case "VIEW-TRIP-DISABLE":
                        vm.viewTripDisable = index.viewTripDisable;
                        break;
                    }
                }
            });
    };
    getBookingTripRules();

}

export default {
    name: 'MyAccountCtrl',
    fn: MyAccountCtrl
};
