'use strict';

function VehicleViewService(HttpRequestService, ConfigService) {
    'ngInject';

    const service = {};

    service.getVehicleLocation = (vehicleId, date) => {
        const queryString = "/"+vehicleId+"/date/"+date;
        return HttpRequestService.get(ConfigService.api.GET_VEHICLE_VIEW + queryString);
    };

    return service;
}

export default {
    name: 'VehicleViewService',
    fn: VehicleViewService
};
