'use strict';

function OnRun($rootScope, $state, APP_CONFIG,
    Auth, $location, menu, ConfigService, AgencyResource, AgencyService) {
    'ngInject';

    let locationSearch;
    $rootScope.$on('$stateChangeStart', (event, toState, toParams, fromState) => {
        locationSearch = $location.search();

        // Set login-url in local storage
        const pathname = window.location.pathname;
        if (pathname && pathname.includes("/login")) {
            if (fromState.name === "" && localStorage.getItem("token")) {
                localStorage.clear();
            }
            if (!localStorage.getItem("login-url")) {
                localStorage.setItem("login-url", window.location.href);
            }
        }

        if (toState.redirectTo) {
            event.preventDefault();
            $state.go(toState.redirectTo, toParams, { location: 'replace' });
        }

        if (toState.name === 'RegLogin.login' || toState.name === 'RegLogin.register') {
            if (angular.isUndefined(toParams.a)) {
                event.preventDefault();
                $state.go('Landing');
            }

            const assetUrl = ConfigService.getAssetUrl();

            if (assetUrl === null) {
                event.preventDefault();

                // logout clears all local storage, so setting agency again, if needed
                if (!AgencyService.getAgency()) {
                    AgencyService.setAgency(toParams.a);
                }

                AgencyResource.configAssetUrl(AgencyService.getAgency())
                    .catch(() => {
                        toParams.a = 'on_yrt';
                    })
                    .finally(() => {
                        $state.go(toState.name, toParams, {
                            reload: true,
                            location: "replace"
                        });
                    });
            }

        } else {

            const agency = $location.search().a;

            if (!ConfigService.getAssetUrl()) {
                event.preventDefault();
                AgencyResource.configAssetUrl(agency)
                    .then(() => {
                        $state.go(toState.name, toParams, {
                            reload: true,
                            location: "replace"
                        });
                    }).catch(() => {
                        $state.go('RegLogin.login');
                    });
            }

            if (!ConfigService.getBaseUrl()) {
                event.preventDefault();
                AgencyResource.configBaseUrl(agency).then(() => {
                    $state.go(toState.name);
                }).catch(() => {
                    $state.go('RegLogin.Login');
                });
            } else if ('data' in toState && 'access' in toState.data) {
                if (!Auth.isAuthorized(toState.data.access)) {
                    event.preventDefault();
                    $state.go('RegLogin.login');
                }
            }
            menu.loadTripRule();
        }
    });

    $rootScope.$on('$stateChangeSuccess', (toState, fromState) => {
        $state.previous = fromState;
        $rootScope.pageTitle = '';
        if (toState.title) {
            $rootScope.pageTitle += toState.title;
            $rootScope.pageTitle += ' \u2014 ';
        }
        $rootScope.pageTitle += APP_CONFIG.app_name;
        if (locationSearch.a && toState.name === "RegLogin.login" || toState.name === "RegLogin.register") {
            $location.search(locationSearch);
        }
    });

    $rootScope.$on('StartLoading', () => {
        $rootScope.isLoading = true;
    });


    $rootScope.$on('FinishLoading', () => {
        $rootScope.isLoading = false;
    });

}

export default OnRun;
