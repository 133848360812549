'use strict';

function ValidationDirective($interpolate, $parse) {
    "ngInject";

    return {
        require: 'ngModel',
        link: (scope, elm, attr, ctrl) => {
            const pwdToMatch = $parse(attr.passwordValidation);
            const pwdFn = $interpolate(attr.passwordValidation)(scope);
            scope.$watch(pwdFn, (newVal) => {
                ctrl.$setValidity('password', ctrl.$viewValue === newVal);
            });
            ctrl.$validators.password = (modelValue, viewValue) => {
                const value = modelValue || viewValue;
                return value === pwdToMatch(scope);
            };
        }
    };
}

export default {
    name: 'passwordValidation',
    fn: ValidationDirective
};