'use strict';

function TripInfoCtrl($scope) {
    "ngInject";

    $scope.openDatePicker = () => {
        $scope.popup1 = true;
    };

    $scope.mytime = new Date();
    $scope.displayTime = 'n/a';

    $scope.$watch('mytime', function () {
        const hour = $scope.mytime.getHours() - ($scope.mytime.getHours() >= 12 ? 12 : 0),
            formattedHour = hour < 10 ? '0' + hour : hour,
            minutes = ($scope.mytime.getMinutes() < 10 ? '0' : '') + $scope.mytime.getMinutes(),
            period = $scope.mytime.getHours() >= 12 ? 'PM' : 'AM';
        $scope.displayTime = formattedHour + ':' + minutes + ' ' + period;
    });

    $scope.hstep = 1;
    $scope.mstep = 15;

    $scope.options = {
        hstep: [1, 2, 3],
        mstep: [1, 5, 10, 15, 25, 30]
    };


    $scope.ismeridian = true;
    $scope.toggleMode = function () {
        $scope.ismeridian = !$scope.ismeridian;
    };
}

export default {
    name: 'TripInfoCtrl',
    fn: TripInfoCtrl
};
