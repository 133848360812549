'use strict';

function AboutCtrl(AgencyInfoService, $sce, _, $log) {
    "ngInject";

    const vm = this;
    vm.aboutInfo = {
        info: []
    };

    // Already filtered by today's date, browser language, and device type = browser
    AgencyInfoService.getResources('about').then(data => {
        if (data) {
            vm.aboutInfo.info = _.map(data, function (entry) {
                return {
                    text: $sce.trustAsHtml(entry.text)
                };
            });
        } else {
            vm.aboutInfo.info = [{
                text: $sce.trustAsHtml("This page is not available right now. Please check back later.")
            }];
        }
    }, err => {
        $log.error(err);
    });

}

export default {
    name: 'AboutCtrl',
    fn: AboutCtrl
};
