'use strict';

function NotificationsCtrl() {
    'ngInject';
}

export default {
    name: 'NotificationsCtrl',
    fn: NotificationsCtrl
};
