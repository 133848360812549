'use strict';

function SettingsCtrl() {
    'ngInject';
}

export default {
    name: 'SettingsCtrl',
    fn: SettingsCtrl
};
