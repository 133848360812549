'use strict';

function TripDetailsUtils(AgencyInfoService, moment) {
    'ngInject';

    // return object
    const utils = {};
    const propertiesToMerge = {};
    const glyphCheck = 'glyphicon-check';
    const glyphExclamation = 'glyphicon-exclamation-sign';
    const green = 'trip-details-green';
    const blue = 'trip-details-blue';
    const red = 'trip-details-red';

    // status properties [0, 1, 4, 5]
    utils.statusOf = [
        {   // 0 = Unkown status / error
            statusColor: red,
            statusMessage: 'Trip status is unknown.  Please contact your transit agency.',
            statusIcon: glyphCheck,
            statusString: 'UNKNOWN'
        },
        {   // 1 = Trip Complete
            statusColor: green,
            statusMessage: 'Trip has been completed',
            statusIcon: glyphCheck,
            statusString: 'TRIP COMPLETED'
        },
        {   // 2 = Trip Approved
            statusColor: blue,
            statusMessage: 'Trip is approved.  Please check time before departure.',
            statusIcon: glyphCheck,
            statusString: 'TRIP APPROVED'
        },
        {   // 3 = Trip Scheduled
            statusColor: green,
            statusMessage: 'Trip is scheduled.',
            statusIcon: glyphCheck,
            statusString: 'SCHEDULED'
        },
        {   // 4 = Trip Cancelled
            statusColor: red,
            statusMessage: 'Trip has been cancelled.',
            statusIcon: glyphExclamation,
            statusString: 'CANCELLED'
        },
        {   // 5 = Trip is Requesting/Pending
            statusColor: red,
            statusMessage: 'Trip is requested and pending approval.  Your exact trip time will be reflected once the trip is scheduled by your transit agency.',
            statusIcon: glyphExclamation,
            statusString: 'REQUESTING'
        },
        { // 6 = Rider is Onboard
            statusColor: green,
            statusMessage: 'Trip is in progress',
            statusIcon: glyphCheck,
            statusString: 'RIDER ONBOARD'
        }
    ];

    // Simply add business rule identity here to return all applicable rules through out trip details sections
    // NOTE: AgencyInfoService.multiCategoryBusinessRules(categories) method allows for multi category endpoint call
    utils.applicableRules = [
        "MINUTES-RESTRICT-CANCEL",
        "DISPLAY_TRIP_PICKUP_TIME_WINDOW",
        "DISPLAY_INITIAL_NEGOTIATED_PICKUP_TIME_WINDOW",
        "MINUTES_BEFORE_SCHEDULED_PICKUP_TIME",
        "MINUTES_AFTER_SCHEDULED_PICKUP_TIME",
        "MINUTES_OFFSET_RECOMPUTE_SCHEDULED_PICKUP",
        "CANCEL-TRIP-DISABLE", // RID-2681: User is able to cancel a trip with CANCEL-TRIP-DISABLE business rule set to true (Portal)
        "SCHEDULED-TRIPS-THRESHOLD-MINUTES", // RID-2982: If the driver is 1 minute late, the trip is displaying as a cancelled trip
        "ALWAYS_COMPUTE_TIME_WINDOW", // DEX-13
        "SHOW-VEHICLE-PRIOR-TO-SCHEDULE-TIME", // DEX-83
    ];

    // RID-2982: If the driver is 1 minute late, the trip is displaying as a cancelled trip
    utils.isTripWithinScheduledThreshold = (departTime, schedTripsThresholdMinutes) => {
        const now = moment();
        const depart = moment(departTime);
        const diff = now.diff(depart, 'minutes');

        // IF depart time is in the future, we DO want this function to return true, which is what will happen since moment.diff of a future time returns a negative
        return (diff < schedTripsThresholdMinutes);
    };

    utils.getStatusProperties = (statusId) => {
        return utils.statusOf[statusId] || utils.statusOf[0];
    };

    // DEX-83: Issue with ETA display and Pickup window display for MBTA
    utils.setETAMapTime = function ({ status = 0, steps, showVehiclePriorToScheduleTime }) {
        // let's return null right away if status is not applicable    
        if (![2, 3, 6].includes(status)) return null;

        const departTime = moment(steps[0].departTime);
        const arrivalTime = moment(steps[0].arrivalTime);
        const now = moment();
        const timeDelta = departTime.diff(now, 'seconds');

        if (status !== 6) {  // if trip approved or scheduled and rider not on board
            return (timeDelta > showVehiclePriorToScheduleTime) ? 'TBD' : departTime.format('LT');
        } else { // if rider on board status 6
            return arrivalTime.format('LT');
        }
    };

    // DEX-40 | DEX-41: proper way to handle if trip is cancellable
    utils.isCancellable = function (departTimeNotFormatted, arrivalTimeNotFormatted, minutesRestrictCancelRule) {
        const departTimeMoment = moment(departTimeNotFormatted);
        const arrivalTimeMoment = moment(arrivalTimeNotFormatted);

        // need to check for edge cases where depart time might be null/invalid and we would then have to use arrival time
        const usableMoment = (departTimeMoment.isValid()) ? departTimeMoment : arrivalTimeMoment;

        // checking difference in time between NOW and usable time (either depart or arrival time)
        // NOTE: diff of time must be in minutes due to business rule being in minutes
        const timeDelta = usableMoment.diff(moment(), 'minutes');

        return (timeDelta >= minutesRestrictCancelRule);
    };

    utils.calculateTimeToDisplay = (trip, vm) => {
        // depart time and arrival times (formatted and unformatted)
        const departTimeNoFormat = moment(trip.steps[0].departTime);
        const isDepartTimeValid = departTimeNoFormat.isValid();
        const departTimeFormatted = (isDepartTimeValid) ? departTimeNoFormat.format('LT') : '';

        const arrivalTimeNoFormat = moment(trip.steps[0].arrivalTime);
        const isArrivalTimeValid = arrivalTimeNoFormat.isValid();
        const arrivalTimeFormatted = (isArrivalTimeValid) ? arrivalTimeNoFormat.format('LT') : '';

        // PICKUP - initial (min)
        const initialPickupEarlyOffset = trip.steps[0].pickup.initialEarly;
        const initialPickupLateOffset = trip.steps[0].pickup.initialLate;

        // PICKUP - negotiated (min)
        const negotiatedPickupEarlyOffset = trip.steps[0].pickup.negotiatedEarly;
        const negotiatedPickupLateOffset = trip.steps[0].pickup.negotiatedLate;

        // DROPOFF - initial (min)
        const initialDropoffEarlyOffset = trip.steps[0].dropoff.initialEarly;
        const initialDropoffLateOffset = trip.steps[0].dropoff.initialLate;

        // DROPOFF - negotiated (min)
        // const negotiatedDropoffEarlyOffset = trip.steps[0].dropoff.negotiatedEarly;
        // const negotiatedDropoffLateOffset = trip.steps[0].dropoff.negotiatedLate;

        // DEPART time window (start/end) -> 
        // const departStartTimeOfWindow = moment(departTimeNoFormat).subtract(initialPickupEarlyOffset, 'minutes').format('LT');
        // const departEndTimeOfWindow = moment(departTimeNoFormat).add(initialPickupLateOffset, 'minutes').format('LT');

        // ARRIVAL time window (start/end)
        // const arrivalStartTimeOfWindow = moment(arrivalTimeNoFormat).subtract(initialDropoffEarlyOffset, 'minutes').format('LT');
        // const arrivalEndTimeOfWindow = moment(arrivalTimeNoFormat).add(initialDropoffLateOffset, 'minutes').format('LT');

        // Setting statusOf message and string properties to defaults
        Object.assign(propertiesToMerge, utils.getStatusProperties(vm.tripInfo.status));

        if (vm.tripInfo.status === 4 || vm.tripInfo.status === 1) { // Is status of trip CANCELLED OR COMPLETED            
            propertiesToMerge.departTime = departTimeFormatted;
            propertiesToMerge.destTime = arrivalTimeFormatted;
        } else if (vm.tripInfo.status == 6 || vm.tripInfo.isPassengerOnboard) { // passenger is on board regardless of status
            propertiesToMerge.departTime = departTimeFormatted;
            propertiesToMerge.destTime = (isArrivalTimeValid) ? `${arrivalTimeFormatted} ETA` : 'ETA TBD';
        } else if (vm.tripInfo.status === 5) { // Is the status PENDING/REQUESTING
            propertiesToMerge.departTime = (isDepartTimeValid ? `${departTimeFormatted} ` : '') + 'Awaiting Approval';
            propertiesToMerge.destTime = (isArrivalTimeValid ? `${arrivalTimeFormatted} ` : '') + 'Awaiting Approval';
        } else if (vm.businessRules.displayTripPickupTimeWindow) {
            if (vm.tripInfo.status === 2) { // Is the status TRIP APPROVED
                propertiesToMerge.departTime = (isDepartTimeValid ? `${departTimeFormatted} ` : '') + 'Awaiting Scheduling';
                propertiesToMerge.destTime = (isArrivalTimeValid ? `${arrivalTimeFormatted} ` : '') + 'Awaiting Scheduling';

                // if status is TRIP APPROVED/UNSCHEDULED, ADA, and Business rule true to show intial/negotiated time window                
                if (vm.businessRules.initialNegotiatedPickupTimeWindow) {
                    if (isDepartTimeValid) {
                        const initialPickupStartTime = departTimeNoFormat.subtract(initialPickupEarlyOffset, 'minutes').format('LT');
                        const initialPickupEndTime = departTimeNoFormat.add(initialPickupLateOffset, 'minutes').format('LT');
                        const initialPickupTimeWindow = `${initialPickupStartTime} - ${initialPickupEndTime}`;
                        propertiesToMerge.departTime = `${initialPickupTimeWindow} Awaiting Scheduling`;
                    } else {
                        propertiesToMerge.departTime = 'Awaiting Scheduling';
                    }

                    if (!vm.tripInfo.isPickup && isArrivalTimeValid) { // dropoff
                        const initialDropoffStartTime = arrivalTimeNoFormat.subtract(initialDropoffEarlyOffset, 'minutes').format('LT');
                        const initialDropoffEndTime = arrivalTimeNoFormat.add(initialDropoffLateOffset, 'minutes').format('LT');
                        const initialDropoffTimeWindow = `${initialDropoffStartTime} - ${initialDropoffEndTime}`;
                        propertiesToMerge.destTime = `${initialDropoffTimeWindow} Awaiting Scheduling`;
                    } else {
                        propertiesToMerge.destTime = 'Awaiting Scheduling';
                    }
                }
            } else if (vm.tripInfo.isADA && !vm.businessRules.alwaysComputeTimeWindow) { // then trip is SCHEDULED and ADA (negotiated times)
                if (isDepartTimeValid) {
                    const negotiatedPickupStartTime = departTimeNoFormat.subtract(negotiatedPickupEarlyOffset, 'minutes').format('LT');
                    const negotiatedPickupEndTime = departTimeNoFormat.add(negotiatedPickupLateOffset, 'minutes').format('LT');
                    const negotiatedPickupTimeWindow = `${negotiatedPickupStartTime} - ${negotiatedPickupEndTime}`;

                    if (!vm.tripInfo.isPassengerOnboard) { // driver NOT at pickup location bc passenger is NOT onboard                    
                        propertiesToMerge.statusMessage = `You will depart ${negotiatedPickupTimeWindow}`;
                        propertiesToMerge.departTime = `${negotiatedPickupTimeWindow} DEPARTURE`;
                        propertiesToMerge.destTime = 'AWAITING ETA';
                    }

                    if (!vm.tripInfo.isPickup) { // dropoff
                        propertiesToMerge.destTime = 'Awaiting ETA';
                    }
                }
            } else if (!vm.tripInfo.isPassengerOnboard) { // driver NOT at pickup location bc passenger is NOT on board
                const formattedComputedEarly = moment(vm.tripInfo.computedDepartTimeEarly).format('LT');
                const formattedComputedLate = moment(vm.tripInfo.computedDepartTimeLate).format('LT');
                const thisBlockTimeWindow = `${formattedComputedEarly} - ${formattedComputedLate}`;
                propertiesToMerge.statusMessage = `You will depart ${thisBlockTimeWindow}`;
                propertiesToMerge.departTime = `${thisBlockTimeWindow} DEPARTURE`;
                propertiesToMerge.destTime = 'Awaiting ETA';
            }
        } else if (vm.tripInfo.status === 2) { // Is the status TRIP APPROVED
            propertiesToMerge.departTime = (isDepartTimeValid ? `${departTimeFormatted} ` : '') + 'Awaiting Scheduling';
            propertiesToMerge.destTime = (isArrivalTimeValid ? `${arrivalTimeFormatted} ` : '') + 'Awaiting Scheduling';
        } else if (vm.tripInfo.status === 3) { // Is the status SCHEDULED and rider NOT on board
            propertiesToMerge.departTime = (isDepartTimeValid) ? `${departTimeFormatted} DEPARTURE` : 'TBD';
            propertiesToMerge.statusMessage = (isDepartTimeValid) ? `You will depart at ${departTimeFormatted}` : 'Departure time TBD';
            propertiesToMerge.destTime = (isArrivalTimeValid ? `${arrivalTimeFormatted} ` : '') + 'Awaiting ETA';
        }

        return propertiesToMerge;
    };

    // return service and all properties to merge
    return utils;
}

export default {
    name: 'TripDetailsUtils',
    fn: TripDetailsUtils
};
