'use strict';

function LandingCtrl($state, $location) {
    "ngInject";

    const vm = this;

    vm.register = () => {
        const agency = $location.search().a;
        $state.go('RegLogin.register', {a: agency});
    };

    vm.login = () => {
        const agency = $location.search().a;
        $state.go('RegLogin.login', {a: agency});
    };

}

export default {
    name: 'LandingCtrl',
    fn: LandingCtrl
};
