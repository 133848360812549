'use strict';
import agency_info from 'com-npm-node-agency-finder';

function AgencyResource($q, ConfigService) {
    'ngInject';

    const factory = {};
    let cachedAgencyAbrv;

    factory.configBaseUrl = agencyAbrv => {
        return $q((resolve, reject) => {
            if (ConfigService.getBaseUrl() && cachedAgencyAbrv === agencyAbrv) {
                resolve();
            } else if (agencyAbrv) {
                cachedAgencyAbrv = agencyAbrv;
                agency_info.findAgencyByAbbreviation(agencyAbrv, ((err, resp) => {
                    if (err) {
                        reject();
                        return;
                    }
                    ConfigService.setBaseUrl(resp.agency.dataCentreURL);
                    resolve();
                }));
            } else {
                reject();
            }
        });
    };

    factory.configAssetUrl = agencyAbrv => {
        return $q((resolve, reject) => {
            if (ConfigService.getAssetUrl() && cachedAgencyAbrv === agencyAbrv) {
                resolve();
            } else if (agencyAbrv) {
                cachedAgencyAbrv = agencyAbrv;
                agency_info.findAgencyByAbbreviation(agencyAbrv, ((err, resp) => {
                    if (err) {
                        ConfigService.setAssetUrl('');
                    } else {
                        ConfigService.setAssetUrl(resp.agency.agencyAssetsURL);
                    }
                    resolve();
                }));
            } else {
                reject();
            }
        });
    };

    return factory;
}

export default {
    name: 'AgencyResource',
    fn: AgencyResource
};
