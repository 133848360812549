'use strict';

function BookingService($http, ConfigService, HttpRequestService, $log,
    JWTService,
    AgencyInfoService) {
    'ngInject';

    const service = {
        book_min_balance: null
    };

    service.bookTrip = (tripInfo, userId) => {
        const bookingUrl = ConfigService.api.BOOK_TRIP + userId;
        return HttpRequestService.post(bookingUrl, tripInfo, {});
    };

    service.bookingPermitted = () => {
        if (!service.book_min_balance) {
            service.getText();
        }
        const bookingPermittedUrl = ConfigService.api.BOOKING_PERMITTED;
        $log.debug('bookingPermittedUrl:', bookingPermittedUrl);
        return HttpRequestService.get(bookingPermittedUrl, {});    
    };

    service.getText = () => {
        AgencyInfoService.getResources('book_min_balance')
            .then( ( [ { text} ] ) => {
                service.book_min_balance = text;
            });
    };

    return service;
}

export default {
    name: 'BookingService',
    fn: BookingService
};
