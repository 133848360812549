'use strict';
require("es5-shim");
require("es6-shim");
require("es7-shim");

function AgencyService($http, ConfigService, HttpRequestService) {
    'ngInject';

    const service = {};

    service.getAgency = () => {
        return localStorage.getItem("agencyAbrv");
    };

    service.setAgency = (agencyAbrv) => {
        localStorage.setItem("agencyAbrv", agencyAbrv);
    };

 
    service.getUrls = (agencyName) => {

        const brandingBaseURL = ConfigService.getAssetUrl();
        // structure required by calling service
        const branding = [{
            asset_category: 'background',
            url: `${brandingBaseURL}/assets/images/${agencyName}/background.svg`
        },
        {
            asset_category: 'logo',
            url: `${brandingBaseURL}/assets/images/${agencyName}/right-main-logo.svg`
        }
        ];

        return Promise.resolve(branding);

    };

    service.loadCustomCSS = (agencyInfo) => {
        if (agencyInfo && (angular.isDefined(agencyInfo.css)) && (agencyInfo.css !== "")){
            const style = document.createElement("style");
            style.appendChild(document.createTextNode(agencyInfo.css));
            document.getElementById('customAgencyCSS').appendChild(style);
        }
    };

    service.getTimeZone = (agencyId) => {
        return HttpRequestService.get(ConfigService.api.GET_TIMEZONE + agencyId);
    };

    return service;

}

export default {
    name: 'AgencyService',
    fn: AgencyService
};
