'use strict';

function RegisterCtrl($scope, _, $state, LoginService, JWTService, $location, AgencyInfoService, $uibModal, ConfigService) {
    "ngInject";

    const vm = this;
    const AssetUrl = ConfigService.getAssetUrl();

    const errMap = {
        'email': {
            fieldName: 'email',
            error: {
                "email_taken": true
            }
        }
    };

    vm.registrationFields = [];
    vm.showCustomerInternalId = false;
    vm.showBirthYear = false;
    vm.showTelephone = false;

    vm.images = [];
    vm.images.right_main_logo = `${AssetUrl}${AgencyInfoService.login_reg.right_main_logo}`;
    vm.images.right_app_logo = `${AssetUrl}${AgencyInfoService.login_reg.right_app_logo}`;

    vm.clearEmailError = () => {
        if ($scope.form.email.$error.email_taken) {
            delete $scope.form.email.$error.email_taken;
        }
    };

    vm.login = () => {
        const agency = $location.search().a;
        $state.go('RegLogin.login', { a: agency });
    };

    vm.privacy = () => {
        vm.modalInstance = $uibModal.open({
            templateUrl: 'modals/modal-privacy.html',
            controller: 'PrivacyCtrl as privacy'
        });
    };

    vm.terms = () => {
        vm.modalInstance = $uibModal.open({
            templateUrl: 'modals/modal-terms.html',
            controller: 'TermsConditionsCtrl as termsAndConditions'
        });
    };

    vm.register = () => {
        vm.rider.agency = $location.search().a;
        LoginService.signup(vm.rider)
            .then(function () {
                vm.modalInstance = $uibModal.open({
                    templateUrl: 'modals/modal-registration-confirmation.html',
                    controller: function ($scope) {
                        $scope.close = () => {
                            return vm.modalInstance.close('Confirmed');
                        };
                    }
                });
                return vm.modalInstance.result;
            })
            .then(() => {

            })
            .catch(function (err) {
                vm.errors = true;
                vm.apiError = err.message;
                if (_.get(err, 'extra.error.message') === 'Empty Query Result') {
                    vm.error = 'Error finding user, please check details';
                } else if (_.get(err, 'extra.message') === 'duplicate key value violates unique constraint "all_users_email_key"') {
                    vm.error = 'Account With This Email Already Exists';
                } else if (_.includes(err.extra.detail, `Key (customer_internal_id)`)) {
                    const detailMessage = err.extra.detail;
                    const cId = detailMessage.replace(/[(customer_internal_id = y x k K .)]/g, '');
                    vm.error = ('Customer ID ' + cId + ' is associated with another email');
                } else if (_.get(err, 'extra') === 'User already found in database. Another email confirmation sent to submitted email address') {
                    vm.error = 'User already registered. Email confirmation resent to email address submitted above';
                    vm.modalInstance = $uibModal.open({
                        templateUrl: 'modals/modal-resend-verification-success.html',
                        controller: function ($scope) {
                            $scope.close = () => {
                                return vm.modalInstance.close('Confirmed');
                            };
                        }
                    });
                } else if (_.get(err, 'message') === 'Resource could not be located') {
                    vm.error = 'Resource could not be located. Please check internal customer information';
                } else {
                    vm.error = vm.apiError;
                }
                const field = errMap[err.error.path].fieldName;
                const ngMsgErr = errMap[err.error.path].error;
                $scope.form[field].$error = ngMsgErr;
            });
    };

    const configureFields = () => {
        if (_.includes(vm.registrationFields, "TP") && _.includes(vm.registrationFields, "BY") && _.includes(vm.registrationFields, "CID")) {
            vm.showBirthYear = true;
            vm.showTelephone = true;
            vm.showCustomerInternalId = true;
        }
        else if (_.includes(vm.registrationFields, "TP") && _.includes(vm.registrationFields, "BY")) {
            vm.showCustomerInternalId = false;
            vm.showBirthYear = true;
            vm.showTelephone = true;
        }
        else if (_.includes(vm.registrationFields, "CID") && _.includes(vm.registrationFields, "TP")) {
            vm.showCustomerInternalId = true;
            vm.showBirthYear = false;
            vm.showTelephone = true;
        }
        else {
            vm.showCustomerInternalId = true;
            vm.showBirthYear = true;
            vm.showTelephone = false;
        }
    };

    const getBookingTripRules = () => {
        AgencyInfoService.bookTripRules().then((data) => {
            const bookTripRules = data[0].rules;
            for (let i = 0; i < bookTripRules.length; i++) {
                const index = bookTripRules[i].rule.rule;
                switch (bookTripRules[i].rule.meta.identity) {
                case "REGISTRATION-FIELDS":
                    vm.registrationFields = index.registrationFields;
                    break;
                }
            }
        }).then(() => {
            configureFields();
        });
    };
    getBookingTripRules();

}

export default {
    name: 'RegisterCtrl',
    fn: RegisterCtrl
};
